import React from 'react';
import { IconProps } from './types';

export const PastInterviewIcon: React.FC<IconProps> = ({size=18, ...rest }) => {
    return (
<svg fill="#E08537" version="1.1" viewBox="144 144 512 512"  width={'80%'}
            height={'80%'} xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m605.01 382.37-37.926 158.32c-2.543 10.617-13.73 19.586-24.434 19.586h-334.55c-4.6484 0-8.5508-1.7031-10.984-4.793-1.707-2.1523-2.6055-4.9805-2.6055-8.1758v-0.91406c0.070313-1.207 0.22266-2.2344 0.46094-3.25l37.918-158.32c2.5508-10.609 13.742-19.578 24.434-19.578h334.55c4.6523 0 8.5547 1.7031 10.992 4.7969 2.5312 3.1758 3.293 7.5547 2.1445 12.328zm-382.34 0c3.707-15.504 18.934-27.648 34.664-27.648h304.48v-44.727c0-10.121-8.2344-18.359-18.359-18.359h-207.43c-10.594 0-19.711-5.2578-25.004-14.438l-16.348-28.305c-3.4141-5.918-9.0625-9.1836-15.902-9.1836h-65.957c-4.8906 0-9.4961 1.9141-12.965 5.3867-3.4844 3.4805-5.3984 8.082-5.3867 12.961l0.046874 241.91z"></path> </g>

</svg>

    )};