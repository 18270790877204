import React from 'react';
import { IconProps } from './types';

export const CandidateIcon: React.FC<IconProps> = ({size, ...rest }) => {
    return (
        <svg fill="#E08537" version="1.1" viewBox="144 144 512 512" 
            width={'70%'}
            height={'70%'}
            xmlns="http://www.w3.org/2000/svg"
            
            >

            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> <g> 
            <path d="m452.48 337.02c0 28.984-23.496 52.48-52.48 52.48s-52.48-23.496-52.48-52.48c0-28.984 23.496-52.48 52.48-52.48s52.48 23.496 52.48 52.48"></path> 
            <path d="m567.93 179.58h-335.87c-13.918 0-27.27 5.5273-37.109 15.371-9.8438 9.8398-15.371 23.191-15.371 37.109v335.87c0 13.922 5.5273 27.27 15.371 37.109 9.8398 9.8438 23.191 15.371 37.109 15.371h335.87c13.922 0 27.27-5.5273 37.109-15.371 9.8438-9.8398 15.371-23.188 15.371-37.109v-335.87c0-13.918-5.5273-27.27-15.371-37.109-9.8398-9.8438-23.188-15.371-37.109-15.371zm-73.473 346.37c0 5.7969-4.6992 10.496-10.496 10.496-5.793 0-10.492-4.6992-10.492-10.496v-41.984c0-26.246-14.004-50.504-36.738-63.625-22.73-13.125-50.738-13.125-73.473 0-22.73 13.121-36.734 37.379-36.734 63.625v41.984c0 5.7969-4.6992 10.496-10.496 10.496s-10.496-4.6992-10.496-10.496v-41.984c0.023438-17.918 5.1367-35.457 14.746-50.586 9.6055-15.125 23.309-27.211 39.516-34.852-17.781-11.621-29.66-30.375-32.574-51.418-2.918-21.043 3.4219-42.316 17.375-58.336 13.953-16.02 34.156-25.215 55.402-25.215 21.242 0 41.445 9.1953 55.402 25.215 13.953 16.02 20.289 37.293 17.375 58.336-2.918 21.043-14.797 39.797-32.578 51.418 16.207 7.6406 29.91 19.727 39.52 34.852 9.6055 15.129 14.719 32.668 14.742 50.586z">
            </path> </g> </g>
        
        </svg>
  )};
