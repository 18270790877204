import '../App.css';
import React, { useState } from 'react';
import {Modal, Button, Form} from 'react-bootstrap';

const backend_key = process.env.REACT_APP_BACKEND_API;

/**
 * DeleteInterviewModal component.
 * 
 * @param {Object} args - The arguments object.
 * @param {boolean} args.setLoading - Function to set the loading state.
 * @param {string} args.Uuid - The UUID of the Preset 
 * @param {string} args.Presets - Preset 
   @param {string} args.SetGrabPreset - Grab the Preset Chosen
 * @param {string} args.setLoadJD - The JD File Page 
   @param {string} args.setPresetOpen
 * @returns {JSX.Element} The ChoosePresetModal component.
 *
**/

  const ChoosePresetModal = (args) =>{
    const [QueryStr, SetQueryStr] = useState('');
    const [QueriedDB, SetQueriedDB] = useState(args.presetData);
   
    const loadPreset = (PresetUuid) => {
      
         fetch(`/getPreset`, {
             method: 'POST',
             headers: {
                 'Content-Type': 'application/json',
                 'my-custom-header-key': backend_key,
             },
             credentials: 'include',
             body: JSON.stringify({uuid: PresetUuid}),
         })
         .then(response => {
             return response.json();
         })
         .then(data => {
            args.SetGrabPreset(data.JDData);
          
            console.log(data.JDData)
            closeModal(data.JDData)
            
         })
         .catch(error => {
             alert('Error retrieving Data');
             console.error(`Fetch error: ${error}`);
         });
     }


     const closeModal =(data) =>{
        args.setPresetOpen(false)
        args.SetDDText(data.Job_Title)
     }

     
return (
    <>
    <Modal show = {args.isOpen} id='choosePresetModal' tabIndex={-1} >
            <Modal.Header closeButton onClick={() => args.setPresetOpen(false)}>
                <Modal.Title>Select Preset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <table className="table table-striped table-hover mx-auto w-75">
                <thead>
                    <tr>
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Description</th>
                    </tr>
                </thead>
                <tbody>
                    {(QueriedDB.length ? (
                    QueriedDB.map((data, num) => (
                        <tr key={num + 1} style={{"cursor": "pointer"}} >
                            <th scope="row" onClick={() => loadPreset(data.uuid)} data-bs-dismiss="modal">{num + 1} </th>
                            <td onClick={() => loadPreset(data.uuid)}>{data.Job_Title}</td>
                            <td onClick={() => loadPreset(data.uuid)}>{data.Job_Description}</td>
                        </tr>
                    ))
                    ):(
                        QueryStr ? (
                            <div className='noResultsMessage'>
                                <h3>No Presets Found</h3>
                            </div>
                        ):
                        <div className='noResultsMessage'>
                            <h3>No Presets In Database</h3>
                        </div>
                    ))}
                </tbody>
        
        </table>
            </Modal.Body>
            <Modal.Footer>
                


            </Modal.Footer>

        </Modal>

      
    </>
)}

export default ChoosePresetModal;
