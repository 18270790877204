import React from 'react';
import { IconProps } from './types';

export const DashboardIcon: React.FC<IconProps> = ({size=18, ...rest }) => {
    return (
      <svg version="1.1" viewBox="-51.2 -51.2 614.40 614.40" xmlns="http://www.w3.org/2000/svg" fill="#e08537"  width={'88%'}
      height={'88%'}  stroke="#e08537"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="1.024"></g><g id="SVGRepo_iconCarrier"> <defs> <clipPath id="a"> <path d="m160 148.09h480v480.91h-480z"></path> </clipPath> </defs> <g clip-path="url(#a)"> <path d="m160.09 484.09h191.93v143.95h-191.93zm0-335.99h191.93v239.91h-191.93zm287.89 0.12109h191.93v143.95h-191.93zm0 239.79h191.93v239.91h-191.93z" fill-rule="evenodd"></path> </g> </g>
      
      </svg>
  )};

  // #E08537