import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

/**
 * A custom progress bar component that displays the progress of an API call.
 *
 * @component
 * @param {number} apiCallTime - The duration of the API call in seconds.
 * @returns {JSX.Element} The rendered MyProgressBar component.
 */
const MyProgressBar = ({ apiCallTime }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
        const totalTime = apiCallTime * 1000; // Convert to milliseconds
        let currentTime = 0;

        const interval = setInterval(() => {
            const newProgress = (currentTime / totalTime) * 100;
            setProgress(newProgress);
            currentTime += 1000; // Update every 1 second

            if (currentTime > totalTime) {
                clearInterval(interval);
                // The API call has likely completed by now, you can perform further actions here.
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
  }, [apiCallTime]);

  return (
    <ProgressBar now={progress} variant="success" animated max={99}/>
  );
};

export default MyProgressBar;
