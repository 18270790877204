import '../App.css';
import '../app.scss';
import ReactDOM from 'react-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from 'react';
import NotificationIcon from '../assets/OrangeNotificationIMG.png';
import { Navbar, Container, Image, Form, FormControl, DropdownToggle, DropdownMenu} from 'react-bootstrap';
import Profile from '../assets/Ravi.jpeg'
import { loadInterview, getAndDownloadInfo} from './utils';
import { getUserName } from '../index.js';


const Dashboard = (args) =>{
    const user = getUserName()
    const [month, setMonth] = useState('January')
    const [PageNum, SetPageNum] = useState(1);
    
    const [openPositions, setOpenPositions] = useState(0)
    const [newRoles, setNewRoles] = useState(0)
    const [candidatesPlaced, setCandidatesPlaced] = useState(0)
    const [totalRoles, setTotalRoles] = useState(0)
    const [candidatePositon, setCandidatePosition] = useState("Consultant")
    const [candidateCompany, setCandidateCompany] = useState("Creospan")
    const [candidateDate, setCandidateDate] = useState("07/26/2024")
    const [viewPastInterviews, setViewPastInterviews] = useState(false)
    const [QueriedDB, SetQueriedDB] = useState(args.interviewsData)
    const isAdmin = args.isAdmin()
    
    var sdBar = ""
    if (!isAdmin){
        sdBar = "grey"
    }
    
    const generateInterviewPage = () =>{
        args.loadPresetsDB()
        args.setJobDescPage(false)
        args.setNewInterview(true)
        args.setDownloadShown(false)
        
    }

    const pastInterviewsPage = () =>{
        args.setJobDescPage(false)
        args.loadDB((!args.viewDB), (args.viewDash))
        args.setDownloadShown(false)
       
    }


    const loadMyInterviews =() =>{
        const myInterviews = document.getElementById("myInterviews")
        const pastInterviews = document.getElementById("pastInterviews")

        myInterviews.style.color = "orange"
        pastInterviews.style.color = "#887e7e"

        args.setNewInterview(false)
        args.loadDB(!args.viewDB, args.viewDash)
    }

    const loadPastInterviews = async () =>{
        const myInterviews = document.getElementById("myInterviews")
        const pastInterviews = document.getElementById("pastInterviews")
        
        myInterviews.style.color = "#887e7e"
        pastInterviews.style.color = "orange"

        args.setNewInterview(false)
        var interviewDB = await args.loadDB(args.viewDB, args.viewDash)
        var lastIndex = (interviewDB.length -1)
        setViewPastInterviews(true)

        
      

    }
    const JobDescriptionPage = () =>{

      
      
        if(isAdmin == true){
            console.log("YOU ARE ADMIN")
            
            args.setJobDescPage(true)
            args.ADDNEW(true)
            args.setFileClicked(false)
        }
        else{
            console.log("You Shall NOT PASS")
        }

    }

   



return (
    <>
    
    
    
    <div className='row main' style={{"marginLeft":'0px',"flex":"1","--bs-gutter-x":"0", alignContent:'flex-start', overflow:'hidden'}}>
           
          
           <Sidebar id='dash' style={{"width":"280px",'color':'white', overflow:'auto', backgroundColor:'#e08537 !important', height:'100vh'}} >
           
                <Menu iconShape="square" style={{"margin-top":"40%", border: '#e08537', backgroundColor:'#e08537' }} menuItemStyles={{button: {'&:hover': {
                backgroundColor: '#C6742F !important', //hover color
                color: '#ffff !important' //text color
                },},}} >
                <MenuItem className = "menu" style={{paddingLeft:'3%'}} >Profile Picture</MenuItem>
                <MenuItem className = "menu"  style={{paddingLeft:'3%'}}>Edit Profile</MenuItem>
                <MenuItem className = "menu"  style={{paddingLeft:'3%'}}>Logout</MenuItem>
                <MenuItem style={{paddingLeft:'1%', backgroundColor:"#e08537"}}>___________________________</MenuItem>
                <MenuItem className = "menu" style={{paddingLeft:'1%'}}><div style={{marginLeft:'3%', marginTop:'5%'}}> Dashboard </div> </MenuItem>
                <MenuItem className = "menu" onClick={generateInterviewPage}  style={{paddingLeft:'3%', marginTop:'1%'}}> Generate New Interviews </MenuItem>
                <MenuItem className = "menu" onClick={pastInterviewsPage} style={{paddingLeft:'3%'}}> Past Interviews </MenuItem>
                <MenuItem className = "menu" onClick={JobDescriptionPage} style={{paddingLeft:'3%', marginTop:'2%', backgroundColor: sdBar}}> Job Description File </MenuItem>
                <MenuItem className = "menu" style={{paddingLeft:'3%', }}> Candidates Profile </MenuItem>
                <MenuItem className = "menu" style={{paddingLeft:'3%', }}> Members </MenuItem>
                <MenuItem className = "menu" style={{paddingLeft:'3%', }}> Settings </MenuItem>
            </Menu>
           </Sidebar>
          

        <div className='row main' style={{backgroundColor:'#f5f5f5', flex:1}}>

        <Navbar className="navbar justify-content-between" style={{height:'70px', backgroundColor:'white', marginBottom:'2%'}}>
            <Navbar.Brand style={{fontWeight:'bold', marginLeft:'3.5%',}}>Welcome {user}!</Navbar.Brand>
   
            <Navbar.Brand href='#'>
                <Image src={NotificationIcon} roundedCircle width="30" height="30"></Image>
            </Navbar.Brand>
   
        </Navbar>


        <div className='row main' style={{width:'90%', maxWidth:'80%'}}>
            
        <div className='row main' style={{backgroundColor:'white', marginLeft:'2%', borderRadius:'6px'}}>
        <h2 style={{fontWeight:'bold', height:'10px', margin:'0px', paddingTop:'4%', paddingBottom:'0px', marginBottom:'11%'}}>Overview</h2>
        <div style={{flex:1, maxWidth:'25%', marginRight:'4%'}}>
            <h4>Month</h4>

            <Dropdown>
            <DropdownToggle variant ="success" style={{width:"100%", borderRadius:'8px', color:'black', 'border-block-color': 'white', backgroundColor: '#e08537', border:'white', 'font-family':'sans-serif', color:'white' }} id='dropdown-basic'>{month}</DropdownToggle>
                <Dropdown.Menu>
                        <Dropdown.Item as = "button"><div onClick={(e) =>setMonth(e.target.textContent)} >January </div></Dropdown.Item>
                        <Dropdown.Item  as = "button"><div onClick={(e) =>setMonth(e.target.textContent)} >February</div></Dropdown.Item>
                        <Dropdown.Item  as = "button"><div onClick={(e) =>setMonth(e.target.textContent)} >March</div></Dropdown.Item>
                        <Dropdown.Item  as = "button"><div onClick={(e) =>setMonth(e.target.textContent)} >April</div></Dropdown.Item>  
                        <Dropdown.Item  as = "button"><div onClick={(e) =>setMonth(e.target.textContent)} >May</div></Dropdown.Item>
                        <Dropdown.Item href="#"><div onClick={(e) =>setMonth(e.target.textContent)} >June</div></Dropdown.Item>  
                        <Dropdown.Item href="#"><div onClick={(e) =>setMonth(e.target.textContent)} >July</div></Dropdown.Item>
                        <Dropdown.Item href="#"><div onClick={(e) =>setMonth(e.target.textContent)} >August</div></Dropdown.Item>
                        <Dropdown.Item href="#"><div onClick={(e) =>setMonth(e.target.textContent)} >September</div></Dropdown.Item>  
                        <Dropdown.Item href="#"><div onClick={(e) =>setMonth(e.target.textContent)} >October</div></Dropdown.Item>
                        <Dropdown.Item href="#"><div onClick={(e) =>setMonth(e.target.textContent)} >November</div></Dropdown.Item>
                        <Dropdown.Item href="#"><div onClick={(e) =>setMonth(e.target.textContent)} >December</div></Dropdown.Item>  
                </Dropdown.Menu>
            </Dropdown>
        </div>
      
      
        <div className='row main'>
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
        <div class="carousel-item active" style={{width:'80% !important'}}>
        <img src="https://creospan.com/wp-content/uploads/2022/09/iStock-1131903874-mobile.png"  style={{width:'80%'}} class="d-block" alt="image1"></img>
        </div>
       
        </div>
        </div>
        </div>

        </div>
            
            <div className='container' style={{backgroundColor:'#7f7eb0', width:'99.1%', padding:0, margin:0, marginTop:'2%', marginLeft:'2%',borderRadius:'6px'}}>
            <div style={{display:'flex'}}>
            <p style={{fontWeight:'bold', fontSize:'17px', paddingLeft:'2%', paddingTop:'2%', marginRight:'2%', color:'white'}}>Interviews</p>
            <button id='myInterviews' onClick={loadMyInterviews} style={{color:'grey',fontSize:'13px',backgroundColor:'#7f7eb0', border:'white', marginRight:'2%', color:'white'}}>My Interviews</button>

            


            <button id='pastInterviews' onClick={loadPastInterviews} style={{color:'grey',fontSize:'13px', backgroundColor:'#7f7eb0', border:'white', color:'white'}}>All Interviews</button>
            {viewPastInterviews && (
                <>
            <table className="table table-striped table-hover mx-auto w-75">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Candidate Name</th>
                    <th scope="col">Job Applied For</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Generated By</th>
                    <th scope="col">Download</th>
                    {/* include last column only if admin user */}
                    <th scope="col">Delete {`(N/A)`}</th>
                 
                    </tr>
                </thead>
                <tbody>
                    {(QueriedDB.length ? (
                    QueriedDB.slice((PageNum-1)*5, 5*PageNum).map((data, num) => (
                        <tr key={num + 1} style={{"cursor": "pointer"}}>
                            <th scope="row" onClick={() => loadInterview(data.uuid, args)}>{num + 1}</th>
                            <td onClick={() => loadInterview(data.uuid, args)}>{data.Candidate_Name}</td>
                            <td onClick={() => loadInterview(data.uuid, args)}>{data.Job_Description_Title}</td>
                            <td onClick={() => loadInterview(data.uuid, args)}>{data.date}</td>
                            <td onClick={() => loadInterview(data.uuid, args)}>{data.user}</td>
                            <td>
                                <button type="button" className="btn btn-primary" onClick={() => getAndDownloadInfo(data.uuid)}>
                                    Download
                                </button>
                            </td>
                            {/* include delete button only if admin user */}
                            
                        </tr>
                    ))
                    ):(
                        
                            <div className='noResultsMessage'>
                                <h3>No Candidates Found</h3>
                            </div>
                       
                    ))}
                </tbody>
                </table>
                </>
            )}

            </div>
              
            </div>
            
           


           
            
        </div>

        <div className='row main' style={{marginBottom:'30%', width:'10%', maxWidth:'30%'}}>

           

            <div className='container row' style={{backgroundColor:'white', marginLeft:'12%', paddingTop:'6%', borderRadius:'6px'}}>
                <p>Recent Hiring</p>
                    <div style={{display:'flex'}}>
                    <Image src={Profile} style={{width:'41%', height:'73%'}}></Image>
                    
                    <div style={{width:'100%'}}>
                        <div>
                    <button className ='sampleUser' style={{width:'67%'}}>Ravi Shah</button>
                        </div>
                    <p class = "candidateSettings" style={{marginTop:'5%'}}>{candidatePositon}</p>
                    <p class = "candidateSettings">{candidateCompany}</p>
                    <p class = "candidateSettings">{candidateDate}</p>
                    </div>
                </div>
            </div>


           

        </div>






</div>
    
    
    
    
</div>
    
    </>
)
}


export default Dashboard;