import React, {useState} from 'react';
import { Document, Packer, Paragraph, TextRun } from "docx";
const backend_key = process.env.REACT_APP_BACKEND_API;


export const loadInterview = (candidateUuid, args) => {
    args.setLoading(true);
    args.viewDash(false);
    args.setViewDB(false);
    fetch(`/getCandidate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'my-custom-header-key': backend_key,
        },
        credentials: 'include',
        body: JSON.stringify({uuid: candidateUuid}),
    })
    .then(response => {
        return response.json();
    })
    .then(data => {
        args.setCandidateInfo(data.candidateData);
        args.setLoading(false);
    })
    .catch(error => {
        alert('Error retrieving Data');
        console.error(`Fetch error: ${error}`);
    });
}
export async function validate_Date()
{
    const date = new Date()
    const format = new Intl.DateTimeFormat("en-US", {
        year:'numeric',
        month:'2-digit',
        day:'2-digit'
    })
    const newDate = format.format(date)
    console.log(newDate)

    if(newDate)
    {
    
    return newDate
    }
    else 
    return null
}

export const getAndDownloadInfo = (candidateUuid) => {
   
    fetch(`/getCandidate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'my-custom-header-key': backend_key,
        },
        credentials: 'include',
        body: JSON.stringify({uuid: candidateUuid}),
    })
    .then(response => {
        return response.json();
    })
    .then(data => {
        console.log(data);
        formatDocxStr(data.candidateData);
    })
    .catch(error => {
        alert('Error retrieving Data');
        console.error(`Fetch error: ${error}`);
    });
}



export const formatDocxStr = (data) => {
    const paragraphs = [];
    
    paragraphs.push(
        // Name and Job Title
        new Paragraph({
            children: [
                new TextRun({
                    text: `Candidate Name:`,
                    bold: true
                }),
                new TextRun({text: ` ${data.Candidate_Name || ''} (${data.Job_Title || ''})`})
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: `Job Applied For:`,
                    bold: true
                }),
                new TextRun({text: ` ${data.Job_Description_Title || ''}`})
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: `Date Generated:`,
                    bold: true
                }),
                new TextRun({text: ` ${data.date || ''}`})
            ]
        }),
        new Paragraph({children: [new TextRun({text: ''})]}),
    );

    // Candidate Strengths
    paragraphs.push(
        new Paragraph({
            children: [
                new TextRun({
                    text: `Strengths of Candidate:`,
                    bold: true,
                    underline: true
                })
            ]
        })
    )
    let strengthsArray = data.strengths.split('\n\n');
    for(let str of strengthsArray){
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({text: `${str || ''}`})
                ]
            }),
            new Paragraph({children: [new TextRun({text: ''})]})
        )
    }

    // Lacking Skills
    paragraphs.push(
        new Paragraph({
            children: [
                new TextRun({
                    text: `Skills Lacking:`,
                    bold: true,
                    underline: true
                })
            ]
        })
    )
    let weaknessArray = data.lacking_qualities.split('\n\n');
    for(let str of weaknessArray){
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({text: `${str || ''}`})
                ]
            }),
            new Paragraph({children: [new TextRun({text: ''})]})
        )
    }

    // Candidate Score
    paragraphs.push(
        new Paragraph({
            children: [
                new TextRun({
                    text: `Candidate Score:`,
                    bold: true,
                    underline: true
                })
            ]
        })
    )
    let scoreArray = data.score.split('\n\n');
    for(let str of scoreArray){
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({text: `${tempFixScore(str) || ''}`})
                ]
            }),
            new Paragraph({children: [new TextRun({text: ''})]})
        )
    }

    // Candidate Assessment
    paragraphs.push(
        new Paragraph({
            children: [
                new TextRun({
                    text: `Candidate Assessment:`,
                    bold: true,
                    underline: true
                })
            ]
        })
    )
    let assessArray = data.assessment.split('\n\n');
    for(let str of assessArray){
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({text: `${str || ''}`})
                ]
            }),
            new Paragraph({children: [new TextRun({text: ''})]})
        )
    }

    // Linkedin Profile Assessment
    paragraphs.push(
        new Paragraph({
            children: [
                new TextRun({
                    text: `LinkedIn Profile & Resume Comparison:`,
                    bold: true,
                    underline: true
                })
            ]
        })
    )
    let linkedinArray = data.linkedCompare.split('\n\n');
    for(let str of linkedinArray){
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({text: `${str || ''}`})
                ]
            }),
            new Paragraph({children: [new TextRun({text: ''})]})
        )
    }

    // Technical Questions
    paragraphs.push(
        new Paragraph({
            children: [
                new TextRun({
                    text: "Technical Questions:", 
                    bold: true,
                    underline: true
                })
            ]
        })
    );
    for (let qa of data.technical_questions) {
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({ 
                        text: `Question:`,
                        bold: true
                    }),
                    new TextRun({ text: ` ${qa.question || ''}` })
                ]
            })
        );
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({ 
                        text: `Answer:`,
                        bold: true
                    }),
                    new TextRun({ text: ` ${qa.answer || ''}` })
                ]
            })
        );
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({ 
                        text: `Notes:`,
                        bold: true
                    }),
                    new TextRun({ text: ` ${qa.notes || ''}` })
                ]
            })
        );
        paragraphs.push(new Paragraph({children: [new TextRun({text: ''})]}));
    }

    // Behavioral Questions
    paragraphs.push(
        new Paragraph({
            children: [
                new TextRun({
                    text: "Behavioral Questions:",
                    bold: true,
                    underline: true
                })
            ]
        })
    );
    for (let qa of data.general_questions) {
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({ 
                        text: `Question:`,
                        bold: true
                    }),
                    new TextRun({ text: ` ${qa.question || ''}` })
                ]
            })
        );
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({ 
                        text: `Answer:`,
                        bold: true
                    }),
                    new TextRun({ text: ` ${qa.answer || ''}` })
                ]
            })
        );
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({ 
                        text: `Notes:`,
                        bold: true
                    }),
                    new TextRun({ text: ` ${qa.notes || ''}` })
                ]
            })
        );
        paragraphs.push(new Paragraph({children: [new TextRun({text: ''})]}));
    }

    // Overall Notes
    paragraphs.push(
        new Paragraph({
            children: [
                new TextRun({
                    text: "Overall Notes:", 
                    bold: true,
                    underline: true
                })
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: `${data.overall_notes || ''}`
                })
            ]
        })
    );

    const doc = new Document({
        sections: [
            {
                properties: {},
                children: paragraphs,
            },
        ],
    });

    Packer.toBlob(doc).then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `${data.Candidate_Name} for ${data.Job_Description_Title} on ${data.date}.docx`;
        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
    });
};

export const tempFixScore = (scoreStr) => {
    let scoreArray = scoreStr.split('\n\n');
    let filteredArr = scoreArray.filter((str) => !str.includes('Education Level') && !str.includes('Communication Skills'));
    let returnStr = filteredArr.join('\n\n');
    return returnStr
}


