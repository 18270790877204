import '../App.css';
import { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { v4 as uuidv4 } from 'uuid';
import DeletePresetModal from '../modals/DeletePresetModal.js';
import JobPresetEditForm from '../modals/JobPresetEditModal.js';
import AssessPresetModal from '../modals/AssessPresetModal.js';
// Side Bar Icons
import { DashboardIcon } from '../assets/icons/dashboard.tsx';
import { GenerateIcon } from '../assets/icons/generate.tsx';
import { PastInterviewIcon } from '../assets/icons/pastinterview.tsx';
import { JobDescriptionIcon} from '../assets/icons/jobdescription.tsx';
import { CandidateIcon } from '../assets/icons/candidateprofile.tsx';
import { MembersIcon } from '../assets/icons/members.tsx';
import { SettingsIcon} from '../assets/icons/settings.tsx';
import { getUserName } from '../index.js';
import { BackArrow } from '../assets/icons/backarrow.tsx';
import { validate_Date } from './utils.js';
import SendToApprovalModal from '../modals/SendToApprovals.js';

const JobDescriptionPage = (args) =>{
    const userName = getUserName()
    const PowerAdmin = args.powerAdmin
    const backend_key = process.env.REACT_APP_BACKEND_API;
    const [ADDNEW, setADDNEW] = useState(args.ADDNEWClicked)
    const [FileCollection, setFileCollection] = useState(args.fileClicked)
    const [PendingApprovals, setPendingApprovals] = useState(args.pendingClicked)
    const [Job_Title, setJobTitle] = useState(null)
    const [Job_Description, setJobDescription] = useState(null)
    const [ClientName, setClientName] = useState(null)
    const [ClientManager, setClientManager] = useState(null)
    const [QueryStr, SetQueryStr] = useState('');
    const [QueriedDB, SetQueriedDB] = useState(PowerAdmin ? args.presetData: args.UNData);
    const [PendingDB, setPendingDB] = useState(args.UNData)
    const [PageNum, SetPageNum] = useState(1);
    const [LastPageNum, SetLastPageNum] = useState(PendingApprovals ? Math.ceil((PendingDB.length || 1) / 10) : Math.ceil((QueriedDB.length || 1) / 10) )
    
    const [deleteUuid, setDeleteUuid] = useState();
    const [deleteDesc, setDeleteDesc] = useState();
    const [deleteJob, setDeleteJob] = useState();
    const [DetectPresetOpen, setPresetOpen] = useState(false)
    const [AssessPreset, setAssessPreset] = useState(false)
    const [SendApproval, setSendApproval] = useState(false)

    useEffect(() =>{
        if(FileCollection && !PowerAdmin && args.selectedPreset.length==0)
            SetQueriedDB(args.UNData?.filter(item =>  
                        userName?.toLowerCase() === item?.Created_By?.toLowerCase() &&
                        item.Status.toLowerCase() !== "pending"
        
    ))
     
    }, [QueriedDB, FileCollection])

    useEffect(() =>{
        if(args.pendingClicked && args.UNData.length!=0 && args.selectedPreset.length==0){
            setPendingDB(args.UNData.filter(item =>  
                item.Status.toLowerCase() === "pending"
                
            ))
         
        }
    }, [args.pendingClicked, PendingDB])
    
    var bcolor = "#e08537"
    var addButtonColor = "grey"
    var fileButtonColor = "grey"
    var pendingButtonColor = "grey"



    const goBack = () =>{
        args.setJobDescPage(false)
        args.setViewDash(true)
     
    }
    
    
    if(args.ADDNEWClicked){
        addButtonColor = "orange"
        fileButtonColor = "grey"
        pendingButtonColor ="grey"
    
    }
    else if(args.fileClicked){
        fileButtonColor = "orange"
        addButtonColor = "grey"
        pendingButtonColor ="grey"
    }
    else if(args.pendingClicked){
        fileButtonColor = "grey"
        addButtonColor = "grey"
        pendingButtonColor ="orange"
      
    }
    else{
     addButtonColor = "orange"
    }
    
    if(!Job_Title || !Job_Description || !ClientManager || !ClientName){
        bcolor = "gray"
    }
    else{
        bcolor = "#e08537"
    }

   
    
    const handleSavePreset = async () =>{
       
        if(Job_Title && Job_Description && ClientName && ClientManager){
           
           const date = await validate_Date()
           if(date){

            if(PowerAdmin==true){

            let saveData = {
                JD_Uuid :uuidv4(),
                Job_Title: Job_Title,
                Job_Description : Job_Description,
                date : date,
                creator: userName,
                Client_Name: ClientName,
                Client_Manager: ClientManager

            };
        args.handleSavePreset(saveData)
        alert("Preset Created")
        FileCollectionClicked()
       
        await args.loadPresetsDB()
        }

        else 
        {
            let saveData = {
                JD_Uuid :uuidv4(),
                Job_Title: Job_Title,
                Job_Description : Job_Description,
                date : date,
                creator: userName,
                Client_Name: ClientName,
                Client_Manager: ClientManager,
                Status : "UNSENT" 
            };
        args.handleSaveUN(saveData)
        alert("Preset UN Created")
        FileCollectionClicked()
       
        await args.loadUN_DB()
        }
        }
        
        
        }

    }

    

    const handleOpenEditor = async (uuid) =>{
        if(PowerAdmin==true && PendingApprovals==false){
          
            const selectedPreset = await loadPreset(uuid)
           
            args.setSelectedPreset(selectedPreset)
           
        
          
            console.log(selectedPreset)
           
      
        }
        else{
          
            const selectedPreset = await loadUNPreset(uuid)
          
            args.setSelectedPreset(selectedPreset)
            console.log(selectedPreset)
        }   
       
    setPresetOpen(true)
    }

    const loadPreset = async (PresetUuid) => {
       
            return await new Promise((resolve, reject) => {
                args.setLoading(true);
                fetch(`/getPreset`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'my-custom-header-key': backend_key,
                    },
                    credentials: 'include',
                    body: JSON.stringify({ uuid: PresetUuid }),
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        args.setPresetData(data.JDData);
                        console.log(data.JDData);
                        args.setSelectedPreset(data.JDData)
                       
                        resolve(data.JDData || [])
                        args.setLoading(false);
                    })

            })
            .catch (error => {
                alert('Error retrieving Data');
                console.error(`Fetch error: ${error}`);
        })
    }

    const loadUNPreset = async (PresetUuid) => {
        return await new Promise((resolve, reject) => {
        args.setLoading(true)
         fetch(`/getUNPreset`, {
             method: 'POST',
             headers: {
                 'Content-Type': 'application/json',
                 'my-custom-header-key': backend_key,
             },
             credentials: 'include',
             body: JSON.stringify({uuid: PresetUuid}),
         })
         .then(response => {
             return response.json();
         })
         .then(data => {
             args.setUNData(data.UNData);
             console.log(data.UNData)
             args.setSelectedPreset(data.UNData)
             resolve(data.UNData || [])
             args.setLoading(false);
            
             
         })
        })
         .catch(error => {
             alert('Error retrieving Data');
             console.error(`Fetch error: ${error}`);
         });
     }
 


    const getPageNum = (page) => {
        if(page === 'Previous'){
            if(PageNum > 1) {
                SetPageNum(PageNum - 1);
            }
        } else if(page === 'Next'){
            if(PageNum < LastPageNum){
                SetPageNum(PageNum + 1);
            }
        } else {
            SetPageNum(page);
        }
    }

    const pastInterviewsPage = () => {
        args.setNewInterview(false)
        args.setJobDescPage(false)
        args.loadDB((!args.viewDB), (args.viewDash))
    
    }

    const getDeleteInfo = (data) => {
        setDeleteUuid(data.uuid);
        setDeleteJob(data.Job_Title);
        setDeleteDesc(data.Job_Description);
    }

    const new_Interviews_Page = async () =>{
        args.setNewInterview(false)
        args.setJobDescPage(false)
        args.setNewInterview(true)
        await args.loadPresetsDB()
    } 

    const Dashboard_Page = () =>{
        goBack()
    }

    const ADDNEWClicked = () =>{
        setADDNEW(true)
        setFileCollection(false)
        args.setPendingClicked(false)
        setPendingApprovals(false)
        var addButton = document.getElementById("add")
        var fileButton = document.getElementById("file")
        addButton.style.color = "orange"
        fileButton.style.color = "grey" 
    //    console.log("ADD NEW Tab: ", args.ADDNEWClicked, "Job Desc: ", args.fileClicked, "pending: ", args.pendingClicked )

    }

    const FileCollectionClicked = async () =>{
        setADDNEW(false)
        args.setPendingClicked(false)
        console.log(PowerAdmin)
        args.setFileClicked(true)
       
        if(PowerAdmin ==true)
            await args.loadPresetsDB() 
        else{
            args.loadUN_DB()
            
            
        
           
        }
      //  console.log("ADD NEW Tab: ", args.ADDNEWClicked, "Job Desc: ", args.fileClicked, "pending: ", args.pendingClicked )
       
    }
    const PendingApprovalsClicked = async () =>{
        
        args.setPendingClicked(true)
        console.log(args.pendingClicked)
        args.setFileClicked(false)
        pendingButtonColor ="orange"
       
        args.loadUN_DB()
       
     //   console.log("ADD NEW Tab: ", args.ADDNEWClicked, "Job Desc: ", args.fileClicked, "pending: ", args.pendingClicked )


    }
    
    const handleQuery = (event) => {
        SetQueryStr(event.target.value);
        if(event.target.value.trim()) {
            SetQueriedDB(args.presetData.filter((item) => {
                if (item.Job_Title.toLowerCase().includes(event.target.value.trim().toLowerCase()) || item.Job_Description.toLowerCase().includes(event.target.value.trim().toLowerCase()) || item.Created_By.toLowerCase().includes(event.target.value.trim().toLowerCase())) {
                    return true;
                }
                return false;
            }));
        } else {
            SetQueriedDB(args.presetData);
        }
        SetPageNum(1);
        SetLastPageNum(Math.ceil((QueriedDB.length || 1) / 10));
    }

    const assessPreset = (uuid) =>{
        const selectedPreset = loadUNPreset(uuid)
        args.setSelectedPreset(selectedPreset)
        console.log(selectedPreset)
        setAssessPreset(true)
    }

    const sendApprovals =async (uuid) =>{
        console.log("PlaceHolder")
        const selectedPreset = loadUNPreset(uuid)
        args.setSelectedPreset(selectedPreset)
        console.log(selectedPreset)
        setSendApproval(true)
        
    }

    /* Row Style for bakground colors */

    const rowColor = (status) => {
        
        
        if(status){
            return{
                
            cursor: "pointer",
          
            
            }
        }
            
       
    }

return (
    <>
    
    <div className='row main' style={{"marginLeft":'0px',"flex":"1","--bs-gutter-x":"0", overflow:'hidden'}}>

   
    <Sidebar id='dash' style={{"width":"280px",'color':'white', overflow:'auto', backgroundColor:'#e08537 !important', height:'100vh'}}>
           
                <Menu iconShape="square" style={{"margin-top":"40%", border: '#e08537', backgroundColor:'#e08537' }} menuItemStyles={{button: {'&:hover': {
                backgroundColor: '#C6742F !important', //hover color
                color: '#ffff !important' //text color
                },},}}>
               <MenuItem className = "menu" style={{paddingLeft:'3%'}}>Profile Picture</MenuItem>
               <MenuItem className = "menu"  style={{paddingLeft:'3%'}}>Edit Profile</MenuItem>
               <MenuItem className = "menu"  style={{paddingLeft:'3%'}}>Logout</MenuItem>
               <MenuItem style={{paddingLeft:'1%', backgroundColor:"#e08537"}}>___________________________</MenuItem>
               <MenuItem className = "menu" onClick ={Dashboard_Page} style={{paddingLeft:'1%'}}><div style={{marginLeft:'3%', marginTop:'5%'}}> Dashboard </div> </MenuItem>
               <MenuItem className = "menu" onClick={new_Interviews_Page}  style={{paddingLeft:'3%', marginTop:'1%'}}> Generate New Interviews </MenuItem>
               <MenuItem className = "menu" onClick={pastInterviewsPage} style={{paddingLeft:'3%'}}> Past Interviews </MenuItem>
               <MenuItem className = "menu" onClick={''} style={{paddingLeft:'3%', marginTop:'2%'}}> Job Description File </MenuItem>
               <MenuItem className = "menu" style={{paddingLeft:'3%', }}> Candidates Profile </MenuItem>
               <MenuItem className = "menu" style={{paddingLeft:'3%', }}> Members </MenuItem>
               <MenuItem className = "menu" style={{paddingLeft:'3%', }}> Settings </MenuItem>
           </Menu>
          </Sidebar>


    <button className='fixed-top' style={{"margin": "30px 0 0 10px", "width":"80px", border:'none', backgroundColor:'#e08537'}} onClick={goBack}><BackArrow></BackArrow></button>
    <div className='main' style={{overflow: "initial", flex:1}}>
    <div className='w-100 file-upload-info d-flex' style={{backgroundColor: 'black', height:'50px', marginBottom:'0px !important', paddingLeft:'3%'}}><h2 style={{marginTop:'1.2%', fontSize:'17px', color:'white'}}>Job Description Files</h2></div>
    <div className='w-100 file-upload-info d-flex css-dip3t8' style={{backgroundColor: 'white', height:'50px', marginBottom:'0px !important', paddingLeft:'3%'}} >
    <div>
    <button onClick={ADDNEWClicked} id='add' style={{fontSize:'13px',backgroundColor:'white', color: addButtonColor, border:'white', marginRight:'2%', height:'100%', width:'103%'}}>Add New</button>
    </div>
    <div style={{marginLeft:'5%'}}>
    <button onClick={FileCollectionClicked} id='file' style={{color: fileButtonColor, fontSize:'13px',backgroundColor:'white', border:'white', marginRight:'2%', height:'100%', width:'100%'}}>Job Descriptions</button>
    </div>
    
    { PowerAdmin && (
 
    <div style={{marginLeft:'5%'}}>
    <button onClick={PendingApprovalsClicked} id='pending' style={{color: pendingButtonColor, fontSize:'13px',backgroundColor:'white', border:'white', marginRight:'2%', height:'100%', width:'100%'}}>Pending Approvals</button>
    </div>
    
    )}
    </div>

    { ADDNEW && (
    <div className='side' style={{backgroundColor:'white', flex:1, marginLeft:'5%'}}>
        <h2 style={{marginBottom:'5%', fontWeight:'bold', marginLeft:'38%', marginTop:'2%'}}>Add New</h2>

        <div className='row' style={{marginBottom:'2%'}}>
            <p style={{width:'12%', paddingLeft:'4%', color:'#5c5555'}}>Job Title:</p>
            <textarea className='JDTextArea' style={{width:'55%', height:'20%', marginLeft:'3%'} } value={Job_Title} onChange={(e) => setJobTitle(e.target.value)}></textarea>
        </div>

       

        <div style={{marginBottom:'2%'}}>
            <p style={{paddingLeft:'2.6%',  color:'#5c5555'}}>Job Description:</p>
            <textarea className='JDTextArea' style={{marginLeft:'2.6%', width:'80%'}} value={Job_Description} onChange={(e) => setJobDescription(e.target.value)}></textarea>
        </div>

        
        <div style={{marginBottom:'2%'}}>
            <p style={{paddingLeft:'2.6%',  color:'#5c5555'}}>Client Name:</p>
            <textarea className='JDTextArea' style={{marginLeft:'2.6%', width:'80%', height:'40px'}} value={ClientName} onChange={(e) => setClientName(e.target.value)}></textarea>
        </div>

        
        <div style={{marginBottom:'2%'}}>
            <p style={{paddingLeft:'2.6%',  color:'#5c5555'}}>Client Manager:</p>
            <textarea className='JDTextArea' style={{marginLeft:'2.6%', width:'80%', height:'40px'}} value={ClientManager} onChange={(e) => setClientManager(e.target.value)}></textarea>
        </div>

        <div style={{width:'13%', marginLeft:'36.7%'}}>
            <button style={{'background-color': bcolor, 'border':'#e08537'}} type="button" className='uploadBtn' onClick={handleSavePreset}>Submit</button>
        </div>
    </div>
    )}

    {FileCollection && (
        <div className='side' style={{backgroundColor:'white', flex:1, marginLeft:'5%'}}>
            <h2 style={{marginBottom:'5%', fontWeight:'bold', marginLeft:'38%', marginTop:'2%'}}>Job Descriptions</h2>

            <div className="input-group mb-4 mx-auto w-75">
                    <span className="input-group-text" id="inputGroup-sizing-default">Search</span>
                    <input type="text" onChange={handleQuery} value={QueryStr} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"></input>
                </div>

                <table className="table table-striped table-hover mx-auto w-75">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Description</th>
                    <th scope="col">Client Name</th>
                    <th scope="col">Client Manager</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Generated By</th>
                  
                    { !PowerAdmin && (
                    <>
                    <th scope='col'>Commit</th>
                    <th scope='col'>Status</th>
                    </>
                   ) }
                    <th scope="col">Delete</th>
                   
                 
                   </tr>

                </thead>
                <tbody>
                    {(QueriedDB.length ? (
                    QueriedDB.slice((PageNum-1)*10, 10*PageNum).map((data, num) => (
                        <tr key={num + 1} style={rowColor(data.Status)} >
                            <th scope="row"  onClick={() => handleOpenEditor(data.uuid)}>{num + 1} </th>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Job_Title}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Job_Description}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Client_Name}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Client_Manager}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.date}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Created_By}</td>
                        
                            { !PowerAdmin && (
                            <>
                            <td><button className="btn" style={{backgroundColor:'#e08537'}} onClick={() => sendApprovals(data.uuid)}> Send to Approvals </button></td>
                            <td onClick={() => handleOpenEditor(data.uuid)}><button className="btn"  style={{backgroundColor:'grey'}}>{data.Status}</button></td>

                            </>
                            )}
                            {/* include delete button only if admin user */}
                          
                            <td>
                                <button type="button" className="btn btn-danger " data-bs-toggle="modal" data-bs-target="#deletePresetModal" onClick={() => getDeleteInfo(data)}>
                                    Delete
                                </button>
                            </td>
                          
                           
                        </tr>
                    ))
                    ):(
                        QueryStr ? (
                            <div className='noResultsMessage'>
                                <h3>No Job Presets Found</h3>
                            </div>
                        ):
                        <div className='noResultsMessage'>
                            <h3>No Job Presets In Database</h3>
                        </div>
                    ))}
                </tbody>
                </table>
            
            {QueriedDB.length ? (
                <nav aria-label="Page navigation example" className='d-flex justify-content-center fixed-bottom mb-2' >
                    <ul className="pagination" >
                        <li className="page-item">
                            <button className="page-link" aria-label="Previous" onClick={() => getPageNum('Previous')} >
                                <span aria-hidden="true">&laquo;</span>
                            </button>
                        </li>
                        {PageNum > 1 ? (
                            <>
                                {PageNum > 2 ? (
                                    <>
                                        {PageNum > 3 ? (
                                            <>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => getPageNum(1)}>1</button>
                                                </li>
                                                {PageNum > 4 ? (
                                                    <li className="page-item">
                                                        <button className="page-link">...</button>
                                                    </li>
                                                ): null}
                                            </>
                                        ): null}
                                        <li className="page-item">
                                            <button className="page-link" onClick={() => getPageNum(PageNum - 2)}>{PageNum - 2}</button>
                                        </li>
                                    </>
                                ):null}
                                <li className="page-item">
                                    <button className="page-link" onClick={() => getPageNum(PageNum - 1)}>{PageNum - 1}</button>
                                </li>
                            </>
                        ): null}
                        <li className="page-item active" aria-current="page">
                            <button className="page-link">{PageNum}</button>
                        </li>
                        {LastPageNum > PageNum ? (
                            <>
                                <li className="page-item">
                                    <button className="page-link" onClick={() => getPageNum(PageNum + 1)}>{PageNum + 1}</button>
                                </li>
                                {LastPageNum > (PageNum + 1) ? (
                                    <>
                                        <li className="page-item">
                                            <button className="page-link" onClick={() => getPageNum(PageNum + 2)}>{PageNum + 2}</button>
                                        </li>
                                        {LastPageNum > (PageNum + 2) ? (
                                            <>
                                                {LastPageNum > (PageNum + 3) ? (
                                                    <li className="page-item">
                                                        <button className="page-link">...</button>
                                                    </li>
                                                ):null}
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => getPageNum(LastPageNum)}>{LastPageNum}</button>
                                                </li>
                                            </>
                                        ): null}
                                    </>
                                ): null}
                            </>
                        ):null}
                        <li className="page-item">
                            <button className="page-link" aria-label="Next" onClick={() => getPageNum('Next')}>
                                <span aria-hidden="true">&raquo;</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            ): null}
            
            </div>
    )}
    {PendingApprovals && (
          <div className='side' style={{backgroundColor:'white', flex:1, marginLeft:'5%'}}>
              <h2 style={{marginBottom:'5%', fontWeight:'bold', marginLeft:'38%', marginTop:'2%'}}>Pending Approvals</h2>
            <div className="input-group mb-4 mx-auto w-75">
                    <span className="input-group-text" id="inputGroup-sizing-default">Search</span>
                    <input type="text" onChange={handleQuery} value={QueryStr} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"></input>
                </div>

                <table className="table table-striped table-hover mx-auto w-75">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Description</th>
                    <th scope="col">Client Name</th>
                    <th scope="col">Client Manager</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Generated By</th>
                    <th scope="col">Approve/Deny</th>
                   
               
                   </tr>

                </thead>
                <tbody>
                    {(PendingDB.length ? (
                    PendingDB.slice((PageNum-1)*10, 10*PageNum).map((data, num) => (
                        <tr key={num + 1} style={{"cursor": "pointer"}}>
                            <th scope="row" onClick={() => handleOpenEditor(data.uuid)}>{num + 1}</th>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Job_Title}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Job_Description}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Client_Name}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Client_Manager}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.date}</td>
                            <td onClick={() => handleOpenEditor(data.uuid)}>{data.Created_By}</td>
                            
                           
                          
                            <td>
                                <button type="button" className="btn btn"  style={{backgroundColor:'#e08537'}} data-bs-toggle="modal" data-bs-target="#approvePresetModal" onClick={() => assessPreset(data.uuid)}>
                                   APPROVE/DENY
                                </button>
                            </td>
                          
                           
                        </tr>
                    ))
                    ):(
                        QueryStr ? (
                            <div className='noResultsMessage'>
                                <h3>No Job Presets Found</h3>
                            </div>
                        ):
                        <div className='noResultsMessage'>
                            <h3>No Job Presets In Database</h3>
                        </div>
                    ))}
                </tbody>
                </table>
            
            {PendingDB.length ? (
                <nav aria-label="Page navigation example" className='d-flex justify-content-center fixed-bottom mb-2' >
                    <ul className="pagination" >
                        <li className="page-item">
                            <button className="page-link" aria-label="Previous" onClick={() => getPageNum('Previous')} >
                                <span aria-hidden="true">&laquo;</span>
                            </button>
                        </li>
                        {PageNum > 1 ? (
                            <>
                                {PageNum > 2 ? (
                                    <>
                                        {PageNum > 3 ? (
                                            <>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => getPageNum(1)}>1</button>
                                                </li>
                                                {PageNum > 4 ? (
                                                    <li className="page-item">
                                                        <button className="page-link">...</button>
                                                    </li>
                                                ): null}
                                            </>
                                        ): null}
                                        <li className="page-item">
                                            <button className="page-link" onClick={() => getPageNum(PageNum - 2)}>{PageNum - 2}</button>
                                        </li>
                                    </>
                                ):null}
                                <li className="page-item">
                                    <button className="page-link" onClick={() => getPageNum(PageNum - 1)}>{PageNum - 1}</button>
                                </li>
                            </>
                        ): null}
                        <li className="page-item active" aria-current="page">
                            <button className="page-link">{PageNum}</button>
                        </li>
                        {LastPageNum > PageNum ? (
                            <>
                                <li className="page-item">
                                    <button className="page-link" onClick={() => getPageNum(PageNum + 1)}>{PageNum + 1}</button>
                                </li>
                                {LastPageNum > (PageNum + 1) ? (
                                    <>
                                        <li className="page-item">
                                            <button className="page-link" onClick={() => getPageNum(PageNum + 2)}>{PageNum + 2}</button>
                                        </li>
                                        {LastPageNum > (PageNum + 2) ? (
                                            <>
                                                {LastPageNum > (PageNum + 3) ? (
                                                    <li className="page-item">
                                                        <button className="page-link">...</button>
                                                    </li>
                                                ):null}
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => getPageNum(LastPageNum)}>{LastPageNum}</button>
                                                </li>
                                            </>
                                        ): null}
                                    </>
                                ): null}
                            </>
                        ):null}
                        <li className="page-item">
                            <button className="page-link" aria-label="Next" onClick={() => getPageNum('Next')}>
                                <span aria-hidden="true">&raquo;</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            ): null}
            
            </div>
    )
    }
    <DeletePresetModal deleteUuid={deleteUuid} deleteJob={deleteJob} deleteDesc={deleteDesc} loadPresetsDB={args.loadPresetsDB} loadPresetsUN={args.loadUN_DB} setLoading={args.setLoading} setLoadJD ={args.setLoadJD}  setViewDash={args.setViewDash} isPowerAdmin ={PowerAdmin}/>
    <JobPresetEditForm presetData ={args.selectedPreset} handleSavePreset ={args.handleSavePreset} setPresetOpen ={setPresetOpen} setJobDescPage = {args.setJobDescPage} isOpen ={DetectPresetOpen} loadPresetsDB={args.loadPresetsDB} setADDNEW ={setADDNEW} isPowerAdmin ={PowerAdmin} handleSaveUN ={args.handleSaveUN} loadUNPresets={args.loadUN_DB} UNData ={args.selectedPreset} PendingApprovals ={PendingApprovals} setSelectedPreset ={args.setSelectedPreset}setOpen ={setPresetOpen} />
    <AssessPresetModal handleSaveUN ={args.handleSaveUN} presetData ={args.selectedPreset} loadUNPresets={args.loadUN_DB} handleSavePreset ={args.handleSavePreset} loadPresetsDB={args.loadPresetsDB} setJobDescPage = {args.setJobDescPage} setADDNEW ={setADDNEW} isOpen ={AssessPreset} setIsOpen ={setAssessPreset} setSelectedPreset ={args.setSelectedPreset}/>
    <SendToApprovalModal isOpen ={SendApproval} setIsOpen ={setSendApproval} presetData ={args.selectedPreset} handleSaveUN ={args.handleSaveUN} setJobDescPage = {args.setJobDescPage} loadUNPresets={args.loadUN_DB} setSelectedPreset ={args.setSelectedPreset} />
    </div>
   </div>
    
    
 
    
    </>
)}



export default JobDescriptionPage;