import '../App.css';
import React, {useEffect, useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const backend_key = process.env.REACT_APP_BACKEND_API;

/**
 * DeleteInterviewModal component.
 * 
 * @param {Object} args - The arguments object.
 * @param {boolean} args.setLoading - Function to set the loading state.
 * @param {string} args.deleteUuid - The UUID of the Preset to be deleted.
 * @param {boolean} args.loadUNPresets - Function to load the UNPresets database.
 * @param {boolean} args.loadPresetsDB - Function to load the JDPresets database.

 * @param {string} args.deleteDesc - The description of the job preset.
 * @param {string} args.deleteJob - The job name for the preset
 * @param {string} args.setLoadJD - The JD File Page 
 * @returns {JSX.Element} The DeletePresetModal component.
 */
const SendToApprovalModal = (args) => {
    const [data, setData] = useState(args.presetData);
    const [JobTitle, SetJobTitle] = useState(data?.Job_Title || "");
    const [JobDescription, SetJobDescription] = useState(data?.Job_Description || "");
    const [Client_Manager, setClientManager] = useState(data?.Client_Manager || "")
    const [Client_Name, setClientName] = useState(data?.Client_Name || "")
    const [Created_By, SetCreatedBy] = useState(data?.creator || "")
    const [Uuid, setUUid] = useState(data?.uuid || "")
    const [date, setDate] = useState(data?.date || "")
    const [openModal, setOpenModal] = useState(false)

    useEffect(() =>{
        if(args.presetData.length != 0 && args.isOpen==true){
            setData(args.presetData)
            
            if(args.isOpen==true){
                setOpenModal(true)
                SetJobTitle(data?.Job_Title || "")
                SetJobDescription(data?.Job_Description || "");
                setClientManager(data?.Client_Manager || "")
                setClientName(data?.Client_Name || "")
                SetCreatedBy(data?.creator || "")
                setUUid(data?.JD_Uuid || "")
                setDate(data?.date || "")
               
            }
        }
    }, [args.presetData, data])


    const savePreset = async () =>
    {
       
        if ((JobDescription && JobTitle && Client_Manager && Client_Name && Created_By) != '')
            {
                 let saveData = {
                    JD_Uuid : Uuid,
                    Job_Title: JobTitle,
                    Job_Description : JobDescription,
                    date : date,
                    creator: Created_By,
                    Client_Name: Client_Name,
                    Client_Manager: Client_Manager,
                    Status: "PENDING"
                 };
     
            args.handleSaveUN(saveData)
            args.setSelectedPreset([])
            args.setJobDescPage(false)
           
            await args.loadUNPresets()
                onClose()
             }
                
            
    }

 
    const onClose = async () =>{
        args.setJobDescPage(false)
        args.setSelectedPreset([])
        args.setIsOpen(false)
        setOpenModal(false)
       
        args.setJobDescPage(true)
        await args.loadUNPresets()
    
    }

    return (
        <>
            <Modal show = {openModal} id='sendToApprovalModal' tabIndex={-1}>
            <Modal.Header closeButton onClick={onClose}>
                <Modal.Title>Approve Preset?</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                       
                            <>
                            <p>
                                {`Send Preset to be Approved?:\n 
                                Job Title:  ${JobTitle}\n- 
                                Job Description: ${JobDescription}\n 
                                Created by ${Created_By} 
                                
                                
                                `
                                
                                
                                
                                } 
                            </p> 
                            <p>
                                THIS CANNOT BE UNDONE!
                            </p>
                            </>
                        
                            <>
                           
                            </>
                       
                </Modal.Body>
                <Modal.Footer>
                    <Button variant ='secondary' style={{backgroundColor:'red'}} onClick={() => onClose()}>NO</Button>
                    <Button style={{backgroundColor:'green'}} variant = 'primary' onClick={() => savePreset()}>SEND</Button>
                </Modal.Footer>      
            </Modal>
        </>
    )
}

export default SendToApprovalModal;