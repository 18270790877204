import '../App.css';
import React, {useState} from 'react';
import DeleteInterviewModal from '../modals/DeleteInterviewModal';
import { Document, Packer, Paragraph, TextRun } from "docx";
import { BackArrow } from '../assets/icons/backarrow.tsx';

const backend_key = process.env.REACT_APP_BACKEND_API;

/**
 * Represents the InterviewDB component.
 * @param {Object} args - The arguments for the component.
 * @returns {JSX.Element} The InterviewDB component.
 */
const InterviewDB = (args) => {    
    const [deleteUuid, setDeleteUuid] = useState();
    const [deleteName, setDeleteName] = useState();
    const [deleteJob, setDeleteJob] = useState();
    const [QueriedDB, SetQueriedDB] = useState(args.interviewsData);
    const [QueryStr, SetQueryStr] = useState('');
    const [PageNum, SetPageNum] = useState(1);
    const [LastPageNum, SetLastPageNum] = useState(Math.ceil((args.interviewsData.length || 1) / 10))
    const PowerAdmin = args.powerAdmin
    /**
     * Loads interview data for a candidate.
     * @param {string} candidateUuid - The UUID of the candidate.
     */
    const loadInterview = (candidateUuid) => {
        args.setLoading(true);
        args.setViewDash(false);
        args.setViewDB(false);
        fetch(`/getCandidate`, {
            method: 'POST',
            headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include',
            body: JSON.stringify({uuid: candidateUuid}),
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            args.setCandidateInfo(data.candidateData);
            args.setLoading(false);
        })
        .catch(error => {
            alert('Error retrieving Data');
            console.error(`Fetch error: ${error}`);
        });
    }

    const goBack =() =>{
        args.setViewDB(false)
        args.setJobDescPage(false)
    }
    
    /**
     * Retrieves candidate information and downloads it.
     * @param {string} candidateUuid - The UUID of the candidate.
     */
    const getAndDownloadInfo = (candidateUuid) => {
        args.setLoading(true);
        fetch(`/getCandidate`, {
            method: 'POST',
            headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include',
            body: JSON.stringify({uuid: candidateUuid}),
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            console.log(data);
            formatDocxStr(data.candidateData);
        })
        .catch(error => {
            alert('Error retrieving Data');
            console.error(`Fetch error: ${error}`);
        });
    }

    /**
     * Formats the data into a Word document (docx) and triggers the download.
     * 
     * @param {Object} data - The data to be formatted into the document.
     */
    const formatDocxStr = (data) => {
        const paragraphs = [];
        
        paragraphs.push(
            // Name and Job Title
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Candidate Name:`,
                        bold: true
                    }),
                    new TextRun({text: ` ${data.Candidate_Name || ''} (${data.Job_Title || ''})`})
                ]
            }),
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Job Applied For:`,
                        bold: true
                    }),
                    new TextRun({text: ` ${data.Job_Description_Title || ''}`})
                ]
            }),
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Date Generated:`,
                        bold: true
                    }),
                    new TextRun({text: ` ${data.date || ''}`})
                ]
            }),
            new Paragraph({children: [new TextRun({text: ''})]}),
        );

        // Candidate Strengths
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Strengths of Candidate:`,
                        bold: true,
                        underline: true
                    })
                ]
            })
        )
        let strengthsArray = data.strengths.split('\n\n');
        for(let str of strengthsArray){
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({text: `${str || ''}`})
                    ]
                }),
                new Paragraph({children: [new TextRun({text: ''})]})
            )
        }

        // Lacking Skills
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Skills Lacking:`,
                        bold: true,
                        underline: true
                    })
                ]
            })
        )
        let weaknessArray = data.lacking_qualities.split('\n\n');
        for(let str of weaknessArray){
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({text: `${str || ''}`})
                    ]
                }),
                new Paragraph({children: [new TextRun({text: ''})]})
            )
        }

        // Candidate Score
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Candidate Score:`,
                        bold: true,
                        underline: true
                    })
                ]
            })
        )
        let scoreArray = data.score.split('\n\n');
        for(let str of scoreArray){
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({text: `${tempFixScore(str) || ''}`})
                    ]
                }),
                new Paragraph({children: [new TextRun({text: ''})]})
            )
        }

        // Candidate Assessment
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Candidate Assessment:`,
                        bold: true,
                        underline: true
                    })
                ]
            })
        )
        let assessArray = data.assessment.split('\n\n');
        for(let str of assessArray){
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({text: `${str || ''}`})
                    ]
                }),
                new Paragraph({children: [new TextRun({text: ''})]})
            )
        }

        // Linkedin Profile Assessment
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `LinkedIn Profile & Resume Comparison:`,
                        bold: true,
                        underline: true
                    })
                ]
            })
        )
        let linkedinArray = data.linkedCompare.split('\n\n');
        for(let str of linkedinArray){
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({text: `${str || ''}`})
                    ]
                }),
                new Paragraph({children: [new TextRun({text: ''})]})
            )
        }

        // Technical Questions
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: "Technical Questions:", 
                        bold: true,
                        underline: true
                    })
                ]
            })
        );
        for (let qa of data.technical_questions) {
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({ 
                            text: `Question:`,
                            bold: true
                        }),
                        new TextRun({ text: ` ${qa.question || ''}` })
                    ]
                })
            );
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({ 
                            text: `Answer:`,
                            bold: true
                        }),
                        new TextRun({ text: ` ${qa.answer || ''}` })
                    ]
                })
            );
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({ 
                            text: `Notes:`,
                            bold: true
                        }),
                        new TextRun({ text: ` ${qa.notes || ''}` })
                    ]
                })
            );
            paragraphs.push(new Paragraph({children: [new TextRun({text: ''})]}));
        }
    
        // Behavioral Questions
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: "Behavioral Questions:",
                        bold: true,
                        underline: true
                    })
                ]
            })
        );
        for (let qa of data.general_questions) {
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({ 
                            text: `Question:`,
                            bold: true
                        }),
                        new TextRun({ text: ` ${qa.question || ''}` })
                    ]
                })
            );
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({ 
                            text: `Answer:`,
                            bold: true
                        }),
                        new TextRun({ text: ` ${qa.answer || ''}` })
                    ]
                })
            );
            paragraphs.push(
                new Paragraph({
                    children: [
                        new TextRun({ 
                            text: `Notes:`,
                            bold: true
                        }),
                        new TextRun({ text: ` ${qa.notes || ''}` })
                    ]
                })
            );
            paragraphs.push(new Paragraph({children: [new TextRun({text: ''})]}));
        }

        // Overall Notes
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: "Overall Notes:", 
                        bold: true,
                        underline: true
                    })
                ]
            }),
            new Paragraph({
                children: [
                    new TextRun({
                        text: `${data.overall_notes || ''}`
                    })
                ]
            })
        );
    
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: paragraphs,
                },
            ],
        });
    
        Packer.toBlob(doc).then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = `${data.Candidate_Name} for ${data.Job_Description_Title} on ${data.date}.docx`;
            document.body.appendChild(a);
    
            a.click();
    
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        });
    };

    // Temporary solution to remove Education Level and Communication Skills from candidate Score. Not to be used as a long term solution
    // TODO: Once backend database can be backed-up. Alter the backend prompt that generates score to remove these fields.
    const tempFixScore = (scoreStr) => {
        let scoreArray = scoreStr.split('\n\n');
        let filteredArr = scoreArray.filter((str) => !str.includes('Education Level') && !str.includes('Communication Skills'));
        let returnStr = filteredArr.join('\n\n');
        return returnStr
    }
    
    const getDeleteInfo = (data) => {
        setDeleteUuid(data.uuid);
        setDeleteName(data.Candidate_Name);
        setDeleteJob(data.Job_Description_Title);
    }

    const handleQuery = (event) => {
        SetQueryStr(event.target.value);
        if(event.target.value.trim()) {
            SetQueriedDB(args.interviewsData.filter((item) => {
                if (item.Candidate_Name.toLowerCase().includes(event.target.value.trim().toLowerCase()) || item.Job_Description_Title.toLowerCase().includes(event.target.value.trim().toLowerCase())) {
                    return true;
                }
                return false;
            }));
        } else {
            SetQueriedDB(args.interviewsData);
        }
        SetPageNum(1);
        SetLastPageNum(Math.ceil((QueriedDB.length || 1) / 10));
    }

    /**
     * Updates the page number based on the given input.
     * @param {string|number} page - The input page value. Can be 'Previous', 'Next', or a specific page number.
     */
    const getPageNum = (page) => {
        if(page === 'Previous'){
            if(PageNum > 1) {
                SetPageNum(PageNum - 1);
            }
        } else if(page === 'Next'){
            if(PageNum < LastPageNum){
                SetPageNum(PageNum + 1);
            }
        } else {
            SetPageNum(page);
        }
    }


    return (
        <>
            <div style={{"margin-top":"25px"}}>
                
                <div className="input-group mb-4 mx-auto w-75">
                    <span className="input-group-text" id="inputGroup-sizing-default">Search</span>
                    <input type="text" onChange={handleQuery} value={QueryStr} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"></input>
                </div>

                <table className="table table-striped table-hover mx-auto w-75">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Candidate Name</th>
                    <th scope="col">Job Applied For</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Generated By</th>
                    <th scope="col">Download</th>
                    { PowerAdmin && (
                    <th scope="col">Delete</th>
                    )}
                    </tr>
                </thead>
                <tbody>
                    {(QueriedDB.length ? (
                    QueriedDB.slice((PageNum-1)*10, 10*PageNum).map((data, num) => (
                        <tr key={num + 1} style={{"cursor": "pointer"}}>
                            <th scope="row" onClick={() => loadInterview(data.uuid)}>{num + 1}</th>
                            <td onClick={() => loadInterview(data.uuid)}>{data.Candidate_Name}</td>
                            <td onClick={() => loadInterview(data.uuid)}>{data.Job_Description_Title}</td>
                            <td onClick={() => loadInterview(data.uuid)}>{data.date}</td>
                            <td onClick={() => loadInterview(data.uuid)}>{data.user}</td>
                            <td>
                                <button type="button" style={{backgroundColor:'#e08537', border: '#e08537'}} className="btn btn-primary" onClick={() => getAndDownloadInfo(data.uuid)}>
                                    Download
                                </button>
                            </td>
                            { PowerAdmin && (
                            <td>
                                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteInterviewModal" onClick={() => getDeleteInfo(data)}>
                                    Delete
                                </button>
                            </td>
                            )}
                        </tr>
                    ))
                    ):(
                        QueryStr ? (
                            <div className='noResultsMessage'>
                                <h3>No Candidates Found</h3>
                            </div>
                        ):
                        <div className='noResultsMessage'>
                            <h3>No Candidates In Database</h3>
                        </div>
                    ))}
                </tbody>
                </table>
            </div>

            <button className='fixed-top btn' style={{"margin": "20px 0 0 20px", "width":"80px", border:'none', backgroundColor:'white'}} onClick={() => goBack()}><BackArrow></BackArrow></button>

            {QueriedDB.length ? (
                <nav aria-label="Page navigation example" className='d-flex justify-content-center fixed-bottom mb-2'>
                    <ul className="pagination">
                        <li className="page-item">
                            <button className="page-link" aria-label="Previous" onClick={() => getPageNum('Previous')}>
                                <span aria-hidden="true">&laquo;</span>
                            </button>
                        </li>
                        {PageNum > 1 ? (
                            <>
                                {PageNum > 2 ? (
                                    <>
                                        {PageNum > 3 ? (
                                            <>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => getPageNum(1)}>1</button>
                                                </li>
                                                {PageNum > 4 ? (
                                                    <li className="page-item">
                                                        <button className="page-link">...</button>
                                                    </li>
                                                ): null}
                                            </>
                                        ): null}
                                        <li className="page-item">
                                            <button style={{backgroundColor:'black'}} className="page-link" onClick={() => getPageNum(PageNum - 2)}>{PageNum - 2}</button>
                                        </li>
                                    </>
                                ):null}
                                <li className="page-item">
                                    <button className="page-link" onClick={() => getPageNum(PageNum - 1)}>{PageNum - 1}</button>
                                </li>
                            </>
                        ): null}
                        <li className="page-item active" aria-current="page">
                            <button className="page-link">{PageNum}</button>
                        </li>
                        {LastPageNum > PageNum ? (
                            <>
                                <li className="page-item">
                                    <button className="page-link" onClick={() => getPageNum(PageNum + 1)}>{PageNum + 1}</button>
                                </li>
                                {LastPageNum > (PageNum + 1) ? (
                                    <>
                                        <li className="page-item">
                                            <button className="page-link" onClick={() => getPageNum(PageNum + 2)}>{PageNum + 2}</button>
                                        </li>
                                        {LastPageNum > (PageNum + 2) ? (
                                            <>
                                                {LastPageNum > (PageNum + 3) ? (
                                                    <li className="page-item">
                                                        <button className="page-link">...</button>
                                                    </li>
                                                ):null}
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => getPageNum(LastPageNum)}>{LastPageNum}</button>
                                                </li>
                                            </>
                                        ): null}
                                    </>
                                ): null}
                            </>
                        ):null}
                        <li className="page-item">
                            <button className="page-link" aria-label="Next" onClick={() => getPageNum('Next')}>
                                <span aria-hidden="true">&raquo;</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            ): null}
            <DeleteInterviewModal deleteUuid={deleteUuid} deleteName={deleteName} deleteJob={deleteJob} loadDB={args.loadDB} setLoading={args.setLoading} setHome={args.setViewDash} viewDB ={args.viewDB} viewDash ={args.viewDash} setViewDB={args.setViewDB} interviewsData={args.interviewsData}/>
        </>
    )
}

export default InterviewDB;
