import  React, { useState,  useEffect } from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import { validate_Date } from '../pages/utils';
import { v4 as uuidv4 } from 'uuid';

/**

@param {Object} args.presetData - The component props.
@param {Function} args.handleSavePreset - The function to be called when the modal is closed.
@returns {JSX.Element} The JobPresetModal component.

**/


const JobPresetEditForm = (args) =>{

    const [data, setData] = useState(args.isPowerAdmin && args.PendingApprovals==false ? args.presetData: args.UNData || "");
    const [JobTitle, SetJobTitle] = useState(data?.Job_Title || "");
    const [JobDescription, SetJobDescription] = useState(data?.Job_Description || "");
    const [Client_Manager, setClientManager] = useState(data?.Client_Manager || "")
    const [Client_Name, setClientName] = useState(data?.Client_Name || "")
    const [Created_By, SetCreatedBy] = useState(data?.creator || "")
    const [Uuid, setUUid] = useState(data?.JD_Uuid || "")
    const [openModal, setOpenModal] = useState(false)
   

    useEffect(() =>{
        
        if(args.presetData.length !=0 || args.UNData.length !=0 && args.isOpen==true)
        {
          
            setData(args.isPowerAdmin && args.PendingApprovals==false ? args.presetData: args.UNData || "")
        
            if(args.isOpen==true)
            {  
                setOpenModal(true)
                SetJobTitle(data?.Job_Title)
                SetJobDescription(data?.Job_Description)
                setClientManager(data?.Client_Manager)
                setClientName(data?.Client_Name)
                SetCreatedBy(data?.creator)
                setUUid(data?.JD_Uuid)
            
                console.log(data?.JD_Uuid)
            }
        } 
        
    } , [data, args.presetData, args.UNData])


    const handleSave = async (uuid) => 
    {
       

        if ((JobDescription && JobTitle && Client_Manager && Client_Name && Created_By) != '')
        {

            const date = await validate_Date()
            
            if(args.isPowerAdmin == true && args.PendingApprovals==false){
            
                if(date){
           
 
             let saveData = {
                 JD_Uuid : uuid,
                 Job_Title: JobTitle,
                 Job_Description : JobDescription,
                 date : date,
                 creator: Created_By,
                 Client_Name: Client_Name,
                 Client_Manager: Client_Manager
 
             };
 
        args.handleSavePreset(saveData)
        args.setJobDescPage(false)
        args.setSelectedPreset([])
        await args.loadPresetsDB()
        
            onClose()
         }
            
        }
    
    else {
        if(date){
           
 
            let saveData = {
                JD_Uuid : uuid,
                Job_Title: JobTitle,
                Job_Description : JobDescription,
                date : date,
                creator: Created_By,
                Client_Name: Client_Name,
                Client_Manager: Client_Manager,
                Status: "UNSENT"
            };

       args.handleSaveUN(saveData)
       args.setJobDescPage(false)
       args.setSelectedPreset([])
       await args.loadUNPresets()
       
           onClose()
        }
    }
    }
    }
    
    const onClose = async () =>{
       
        args.setJobDescPage(false)
        args.setSelectedPreset([])
        if(args.isPowerAdmin ==true && args.PendingApprovals ==false)
            await args.loadPresetsDB()
        else
            await args.loadUNPresets()
        args.setJobDescPage(true)
        args.setPresetOpen(false)
        setOpenModal(false)
    }

    return (
        <>
        
        <Modal show = {openModal} id='editPresetModal' tabIndex={-1}>
            <Modal.Header closeButton onClick={onClose}>
                <Modal.Title>Edit Preset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3'>
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control autoFocus value ={JobTitle} onChange={(e) =>SetJobTitle(e.target.value)}>
                    </Form.Control>
                    </Form.Group>


                    <Form.Group className='mb-3'>
                    <Form.Label>Job Description</Form.Label>
                    <Form.Control as ="textarea" rows={3} value={JobDescription} onChange={(e) => SetJobDescription(e.target.value)}></Form.Control>
                    </Form.Group>


                    <Form.Group className='mb-3'>
                    <Form.Label>Client Name</Form.Label>
                    <Form.Control as ="textarea" rows={3}  value={Client_Name} onChange={(e) => setClientName(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                    <Form.Label>Client Manager</Form.Label>
                    <Form.Control as ="textarea" rows={3} value={Client_Manager} onChange={(e) => setClientManager(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                    <Form.Label>Created By</Form.Label>
                    <Form.Control as ="textarea" rows={3}  value={Created_By} onChange={(e) => SetCreatedBy(e.target.value)}></Form.Control>
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant ='secondary' onClick={() => onClose()}>Close</Button>
                <Button style={{backgroundColor:'orange'}} onClick={() => handleSave(uuidv4())}>Clone</Button>
                <Button style={{backgroundColor:'green'}} variant = 'primary' onClick={() => handleSave(Uuid)}>Save Changes</Button>
               

            </Modal.Footer>

        </Modal>

    </>
    );
}


    
export default JobPresetEditForm