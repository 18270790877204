import React from 'react';
import { IconProps } from './types';

export const JobDescriptionIcon: React.FC<IconProps> = ({size=18, ...rest }) => {
    return (

<svg fill="#E08537" version="1.1" viewBox="144 144 512 512" xmlns="http://www.w3.org/2000/svg"  width={'70%'}
            height={'70%'}><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m169.09 358.02v-125.95c0.019531-16.695 6.6602-32.703 18.465-44.512 11.809-11.805 27.816-18.445 44.512-18.465h137.71-0.003907c13.199 0.03125 26.059 4.1914 36.773 11.898 10.715 7.707 18.75 18.574 22.977 31.078l13.57 40.992h124.84c16.699 0.019531 32.707 6.6602 44.512 18.465 11.809 11.805 18.449 27.812 18.465 44.512v41.984zm0 41.984v167.94-0.003906c0.019531 16.699 6.6602 32.707 18.465 44.512 11.809 11.809 27.816 18.449 44.512 18.465h335.87c16.699-0.015625 32.707-6.6562 44.512-18.465 11.809-11.805 18.449-27.812 18.465-44.512v-167.93z"></path> </g>

</svg>

)};