import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest } from './auth-config';
import InterviewGPT from './InterviewGPT';
// propTypes is a development time validation library that helps you catch errors by validating your props with types.
import PropTypes from 'prop-types';
import backgroundImage from './assets/Login.png'
import creospanLogo from './assets/creospanlogin.png'
/**
 * A wrapped view component that handles authentication and rendering of InterviewGPT component.
 *
 * @returns {JSX.Element} The wrapped view component.
 */
const WrappedView = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    

   

    const handleRedirect = async () => {
        
            instance
        
            .loginRedirect({
                ...loginRequest,
                prompt: 'create',
            })
            
            .catch((error) => console.log(error));
    }

    return (
        <div className='App'>
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <InterviewGPT user={activeAccount.name}/>
                
                ):null}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
            <div style={{backgroundImage: `url(${backgroundImage})`, backgroundSize:'cover'}}>
                <div>
                <img src={creospanLogo}></img>
                </div> 
                
                <div className='signInFlex d-flex justify-content-center align-items-center'>
                <div style={{backgroundColor: 'rgba(255,255,255, 0.5)', width:'60%', height:'50%'}}>
                <div>
                <textarea placeholder="Email" style={{marginLeft:'10%',color: '#857d7d' ,marginBottom:'5%', marginTop:'5%', width:'80%', backgroundColor: 'rgba(255,255,255, 0.1)', border:'rgba(255,255,255, 0.03)', borderBottomColor:'#857d7d', borderBottomStyle:'solid'}}></textarea>
                </div>
                <div>
                <textarea placeholder="Password" style={{marginLeft:'10%',color: '#857d7d' ,marginBottom:'5%', width:'80%', backgroundColor: 'rgba(255,255,255, 0.1)', border:'rgba(255,255,255, 0.03)', borderBottomColor:'#857d7d', borderBottomStyle:'solid'}}></textarea>
                </div>
                    <div style={{width:'100%'}}>
                    <button className='signInBtn' onClick={handleRedirect} style={{width:'35% !important', marginLeft:'40%'}}>
                        LOGIN
                    </button>
                    </div>
                </div>
                </div>
                </div>
            </UnauthenticatedTemplate>
        </div>
      
    )
};

/**
 * Renders the main application component.
 * @param {Object} props - The component props.
 * @param {Object} props.instance - The instance of MsalProvider.
 * @returns {JSX.Element} The rendered component.
 */
const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <WrappedView/>
        </MsalProvider>
    );
};

App.propTypes = {
    instance: PropTypes.object.isRequired,
};

export default App;