import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

/**
 * LinkedInModal component displays a modal for inputting a LinkedIn profile ID.
 * 
 * @param {Object} props - The component props.
 * @param {Function} props.onClose - The function to be called when the modal is closed.
 * @returns {JSX.Element} The LinkedInModal component.
 */
function LinkedInModal({ onClose }) {
  const [inputValue, setInputValue] = useState('');

  const saveInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    // Handle the submission logic
    console.log("submitted value: " + inputValue);
    onClose(inputValue);
  };

  return (
    <Modal show={true} onHide={() => onClose('')} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>No Linkedin Profile Found in Resume</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
            <label for="basic-url" className="form-label">Input LinkedIn Profile ID</label>
            <div className="input-group">
                <span className="input-group-text" id="basic-addon3">https://www.linkedin.com/in/</span>
                <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" onChange={saveInput} value={inputValue}/>
            </div>
            <div className="form-text" id="basic-addon4">Example input: daniel-managlia-3aa195149</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose('')}>
          Continue without LinkedIn
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LinkedInModal;
