import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./auth-config.js";
import './App.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const msalInstance = new PublicClientApplication(msalConfig);
var UserRole = "member"
var UserName =""
function checkUserRoles(roles) {
 
  const requiredRole = "PowerUser"; 
  if (roles && roles.includes(requiredRole)) {
     
      // User has the required role, allow access
      console.log("User has admin access");
      UserRole = (requiredRole)
    
  } else if(roles && roles.includes("PowerAdmin")){
    console.log("User is a Power Admin")
    UserRole = roles
   
  } 
  else {
      // User doesn't have the required role, restrict access
      console.log("User has member access");
      UserRole = "member"
      
  }

}

export function getUserRole(){
  return UserRole
}

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Register a callback function that will be executed after one of the success conditions below is met and 
// event.payload.account exists. Then, active account(which was just authenticated) is set in MSAL instance
msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        msalInstance.setActiveAccount(event.payload.account);
        const account = event.payload.account;
        UserName = account.name
        const idTokenClaims = account.idTokenClaims;
        const roles = idTokenClaims.roles || idTokenClaims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        checkUserRoles(roles[0])
    }
});

export function getUserName(){
  return UserName
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="App">
      <App instance={msalInstance}></App>
    </div>
  </React.StrictMode>
);
