import '../App.css';
import React from 'react';
const backend_key = process.env.REACT_APP_BACKEND_API;

/**
 * DeleteInterviewModal component.
 * 
 * @param {Object} args - The arguments object.
 * @param {boolean} args.setLoading - Function to set the loading state.
 * @param {string} args.deleteUuid - The UUID of the Preset to be deleted.
 * @param {boolean} args.loadPresetsDB - Function to load the JDPresets database.
 * @param {string} args.deleteDesc - The description of the job preset.
 * @param {string} args.deleteJob - The job name for the preset
 * @param {string} args.setLoadJD - The JD File Page 
 * @returns {JSX.Element} The DeletePresetModal component.
 */
const DeletePresetModal = (args) => {
    var route = ""
    
    const deletePreset = () => {
        if(args.isPowerAdmin == true ){
             route = "/deleteJD"
        }
        else{
             route = "/deleteUN"
        }
        fetch(`${route}`, {
            method: 'POST',
            headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include',
            body: JSON.stringify({uuid: args.deleteUuid}),
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            console.log(data);
            if(args.isPowerAdmin == true)
                args.loadPresetsDB()
            else 
                args.loadPresetsUN()
          
            alert('Preset Log Deleted.')
        })
        .catch(error => {
            alert('Error Deleting Preset.')
            console.error(`${error}`);
        });
    }

    return (
        <>
            <div className="modal fade" tabIndex="-1" id="deletePresetModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Preset Data</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                {`Are you sure you want to delete ${args.deleteJob} - ${args.deleteDesc} ?`} 
                            </p>
                            <p>
                                THIS CANNOT BE UNDONE!
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button className="btn btn-danger" data-bs-dismiss="modal" onClick={() => deletePreset()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeletePresetModal;