import '../App.css';
import React from 'react';
import MyProgressBar from '../components/MyProgressBar';

/**
 * Renders a loading component.
 * @param {Object} args - The arguments for the component.
 * @returns {JSX.Element} The loading component.
 */
const Loading = (args) => {
    return (
        <div className='convo-section mx-auto' style={{overflow: "initial"}}>
            <div>
                <div className='w-100 file-upload-info d-flex justify-content-center mb-3'>
                    <p className='m-3 p-0'>
                        Loading
                    </p>
                    <div class="spinner-border text-light mt-3 mb-3 p-0" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                {/* Max time is roughly 6 minutes (subject to change and it currently varies) */}
                {args.AIGenLoading ? <MyProgressBar apiCallTime={360}/> : null}
            </div>
        </div>
    );
}

export default Loading;