import '../App.css';
import TextareaAutosize from 'react-textarea-autosize';
import React, { useState } from 'react';

import { Navbar, Container, Image, Form, FormControl, DropdownToggle, DropdownMenu} from 'react-bootstrap';
import CreospanLogo from '../assets/creospanLogo.png';
import NotificationIcon from '../assets/NotificationIcon.png';
import Profile from '../assets/Ravi.jpeg'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import {getAndDownloadInfo} from './utils.js'

// Side Bar Icons
import { DashboardIcon } from '../assets/icons/dashboard.tsx';
import { GenerateIcon } from '../assets/icons/generate.tsx';
import { PastInterviewIcon } from '../assets/icons/pastinterview.tsx';
import { JobDescriptionIcon} from '../assets/icons/jobdescription.tsx';
import { CandidateIcon } from '../assets/icons/candidateprofile.tsx';
import { MembersIcon } from '../assets/icons/members.tsx';
import { SettingsIcon} from '../assets/icons/settings.tsx';
import { BackArrow } from '../assets/icons/backarrow.tsx';


/**
 * Renders the DisplayInterview component.
 *
 * @param {Object} args - The arguments object.
 * @returns {JSX.Element} The JSX element representing the DisplayInterview component.
 */
const DisplayInterview = (args) => {
   
    const [STRClicked, SetSTRClicked] = useState(true)
    const [CandidateClicked, SetCandidateClicked] = useState(false)
    const [ProfileClicked, SetProfileClicked] = useState(false)
    const [TechnicalClicked, SetTechnicalClicked] = useState(false)
    const [BehavioralClicked, setBehavioralClicked] = useState(false)
    const [ShowDownload, setDownloadShown] = useState(args.showDownloadButton)
    const [ImageFound, setImageFound] = useState(args.imageFound)
    

    


    


    /**
     * Handles the change in notes for a specific section, type, and number.
     * Updates the candidate information accordingly.
     * 
     * @param {string} section - The section of the candidate information (e.g., 'general', 'technical', 'lackingQualities').
     * @param {string} type - The type of information to be updated (e.g., 'question', 'notes', 'answer').
     * @param {number} num - The index of the question or information to be updated.
     * @param {Event} event - The event object representing the change in notes.
     */
    const handleNotesChange = (section, type, num, event) => {
        let newCandidateInfo = {...args.candidateInfo};
        switch(section){
            case 'general':
                type === 'question' ? newCandidateInfo.general_questions[num].question = event.target.value : type === 'notes' ? newCandidateInfo.general_questions[num].notes = event.target.value : newCandidateInfo.general_questions[num].answer = event.target.value;
                break
            case 'technical':
                type === 'question' ? newCandidateInfo.technical_questions[num].question = event.target.value : type === 'notes' ? newCandidateInfo.technical_questions[num].notes = event.target.value : newCandidateInfo.technical_questions[num].answer = event.target.value;
                break
            case 'lackingQualities':
                newCandidateInfo.lacking_qualities = event.target.value;
                break
            case 'strengths':
                newCandidateInfo.strengths = event.target.value;
                break
            case 'assessment':
                newCandidateInfo.assessment = event.target.value;
                break
            case 'linkedCompare':
                newCandidateInfo.linkedCompare = event.target.value;
                break
            case 'overall':
                newCandidateInfo.overall_notes = event.target.value;
                break
            
            default:
                break
        }
        args.setCandidateInfo(newCandidateInfo);
        console.log(newCandidateInfo.lacking_qualities)
    }

    const customQuestion = (type) => {
        let newCandidateInfo = {...args.candidateInfo};
        if(type === 'behavioral') {
            newCandidateInfo.general_questions.push({question: '', answer: '', notes: ''});
        } else {
            newCandidateInfo.technical_questions.push({question: '', answer: '', notes: ''});
        }
        args.setCandidateInfo(newCandidateInfo);
    }

    // saves the candidate information and returns to the home page
    const goBack = () => {
        args.handleSaveCandidate({...args.candidateInfo})
        Dashboard_Page()
      
    }

    const generateInterviewPage =() =>{
        args.setViewDash(true)
        args.setNewInterview(true)
        args.setDownloadShown(false)
        args.setJobDescPage(false)
    }

    const pastInterviewsPage =() =>{
        args.setNewInterview(false)
        args.loadDB((!args.viewDB), (!args.viewDash))
        args.setDownloadShown(false)
        args.setJobDescPage(false)

    }
    const Dashboard_Page = () =>{
        args.setNewInterview(false)
        args.setViewDB(false)
        args.setViewDash(true)
        args.setDownloadShown(false)
        args.setJobDescPage(false)
    }
    const JobDescFilePage =() =>{
        args.setNewInterview(false)
        args.setViewDB(false)
        args.setViewDash(true)
        args.setDownloadShown(false)
        args.setJobDescPage(true)
    }
    
    const handleSTRClicked = () => {

        SetSTRClicked(true)
        SetCandidateClicked(false)
        SetProfileClicked(false)
        SetTechnicalClicked(false)
        setBehavioralClicked(false)
        const strID = document.getElementById("str")
        const scoreID = document.getElementById("score")
        const profileID = document.getElementById("profile")
        const techID = document.getElementById("tech")
        const behavioralID = document.getElementById("behavioral")
       
       
        strID.style.color = "orange"
        scoreID.style.color = "#887e7e"
        profileID.style.color = "#887e7e"
        techID.style.color = "#887e7e"
        behavioralID.style.color = "#887e7e"
    
    }
    const handleCandClicked = () =>{
        SetCandidateClicked(true)
        SetProfileClicked(false)
        SetTechnicalClicked(false)
        setBehavioralClicked(false)
        SetSTRClicked(false)
        const strID = document.getElementById("str")
        const scoreID = document.getElementById("score")
        const profileID = document.getElementById("profile")
        const techID = document.getElementById("tech")
        const behavioralID = document.getElementById("behavioral")
        
        strID.style.color = "#887e7e"
        scoreID.style.color = "orange"
        profileID.style.color = "#887e7e"
        techID.style.color = "#887e7e"
        behavioralID.style.color = "#887e7e"
    
    }
    const handleProfileClicked = () =>{
        SetCandidateClicked(false)
        SetProfileClicked(true)
        SetTechnicalClicked(false)
        setBehavioralClicked(false)
        SetSTRClicked(false)
        const strID = document.getElementById("str")
        const scoreID = document.getElementById("score")
        const profileID = document.getElementById("profile")
        const techID = document.getElementById("tech")
        const behavioralID = document.getElementById("behavioral")
       
        strID.style.color = "#887e7e"
        scoreID.style.color = "#887e7e"
        profileID.style.color = "orange"
        techID.style.color = "#887e7e"
        behavioralID.style.color = "#887e7e"
    }
    const handleTechClicked = () =>{
        SetCandidateClicked(false)
        SetProfileClicked(false)
        SetTechnicalClicked(true)
        setBehavioralClicked(false)
        SetSTRClicked(false)
        const strID = document.getElementById("str")
        const scoreID = document.getElementById("score")
        const profileID = document.getElementById("profile")
        const techID = document.getElementById("tech")
        const behavioralID = document.getElementById("behavioral")
       
        strID.style.color = "#887e7e"
        scoreID.style.color = "#887e7e"
        profileID.style.color = "#887e7e"
        techID.style.color = "orange"
        behavioralID.style.color = "#887e7e"
    }
    const handleBehavioralClicked = () =>{
        SetCandidateClicked(false)
        SetProfileClicked(false)
        SetTechnicalClicked(false)
        setBehavioralClicked(true)
        SetSTRClicked(false)
        const strID = document.getElementById("str")
        const scoreID = document.getElementById("score")
        const profileID = document.getElementById("profile")
        const techID = document.getElementById("tech")
        const behavioralID = document.getElementById("behavioral")
        
        strID.style.color = "#887e7e"
        scoreID.style.color = "#887e7e"
        profileID.style.color = "#887e7e"
        techID.style.color = "#887e7e"
        behavioralID.style.color = "orange"
    }
    
    
       
    const Download_Current_Interview = async () =>{
       
        var interviewDB = await args.loadDB(args.viewDB, (args.viewDash) )
        var lastIndex = (interviewDB.length -1)
        var currentInterview = interviewDB[lastIndex].uuid +''
        getAndDownloadInfo(currentInterview)
    }

    // Temporary solution to remove Education Level and Communication Skills from candidate Score. Not to be used as a long term solution
    // TODO: Once backend database can be backed-up. Alter the backend prompt that generates score to remove these fields.
    const tempFixScore = (scoreStr) => {
        let scoreArray = scoreStr.split('\n\n');
        let filteredArr = scoreArray.filter((str) => !str.includes('Education Level') && !str.includes('Communication Skills'));
        let returnStr = filteredArr.join('\n\n');
        return returnStr
    }

    return (
        <>
             <Navbar className="navbar justify-content-between" style={{"height": "60px", padding:"0px"}}>  
               
                    
               <Navbar.Brand href="#" style={{display:'flex', alignItems:'center'}} > 
               <Image src={CreospanLogo} roundedCircle width="60" height="60" />
               </Navbar.Brand>
              
               
              <Container className='d-flex flex-row-reverse'>
               <Form className='d-flex flex-row-reverse' style={{borderRadius:"50px"}}>
                  <FormControl type='search' placeholder='🔍 Search' style={{display:'flex', alignItems:'center', backgroundColor:'#e49d60', border:'#e08537', paddingRight:"210px", color:'white'}}></FormControl>
               </Form>
               </Container>
             
             
           <Navbar.Brand href='#'>
               <Image src={NotificationIcon} roundedCircle width="30" height="30"></Image>
           </Navbar.Brand>
           
           <Navbar.Brand href ='#'>
               <Image src={Profile} roundedCircle width="40" height="40"></Image>
           </Navbar.Brand>
            
        </Navbar>

        <div className='row main' style={{"marginLeft":'0px',"flex":"1","--bs-gutter-x":"0", backgroundColor:'#f5f5f5'}}>


        <Sidebar style={{"width":"5%", "min-width": '5%','color':'orange', 'overflow':'auto', backgroundColor:'white !important'}}>
            <Menu iconShape="square" style={{"margin-top":"40%", backgroundColor:'white', borderColor: 'white', width:'100%'}} >
            <MenuItem onClick={Dashboard_Page} icon={<DashboardIcon/>} style={{marginLeft:'0%'}}><div style={{marginTop:'5%'}}> </div> </MenuItem>
            <MenuItem onClick={generateInterviewPage} icon={<GenerateIcon/>} style={{marginLeft:'3%', marginTop:'1%'}}>  </MenuItem>
            <MenuItem onClick={pastInterviewsPage} icon={<PastInterviewIcon/>} style={{marginLeft:'3%'}}>  </MenuItem>
            <MenuItem onClick={JobDescFilePage} icon={<JobDescriptionIcon/>} style={{marginLeft:'3%', marginTop:'2%'}}>  </MenuItem>
            <MenuItem icon={<CandidateIcon/>} style={{marginLeft:'3%'}}>  </MenuItem>
            <MenuItem icon={<MembersIcon/>} style={{marginLeft:'3%'}}>  </MenuItem>
            <MenuItem icon={<SettingsIcon/>} style={{marginLeft:'3%'}}> </MenuItem>
            </Menu>
            </Sidebar>

        
            <button className='fixed-bottom ' style={{"margin": "80px 0 0 20px", "width":"80px", border:'none', backgroundColor:'white'}} onClick={goBack}><BackArrow></BackArrow></button>
            <div className='convo-section mx-auto' style={{overflow: "initial", flex:1}}>
                <div style={{backgroundColor:'#f5f5f5'}}>

                <div className='w-100 file-upload-info d-flex justify-content-center' style={{backgroundColor: 'black', height:'50px', marginBottom:'0px !important', paddingLeft:'3%'}}> 
                        <p className='m-3 p-0'>
                        {args.candidateInfo.Candidate_Name || "Error Finding Name"} {args.candidateInfo.Job_Title === 'NONE' ? null : `(${args.candidateInfo.Job_Title})`}
                        </p>
                        <p className='m-3 p-0' style={{'margin-right': '65% !important'}}>
                        {args.candidateInfo.Job_Description_Title}
                        </p>
                    </div>
                    <div className='w-100 file-upload-info d-flex justify-content-center' style={{backgroundColor: 'white', height:'2%', marginBottom:'0% !important'}}> 
                    
                        <button className="btn btn-secondary p-3" id='str' style={{color:"orange", backgroundColor:'white', borderColor:'white', marginLeft:'2% ! important', paddingTop:'0px !important', fontFamily:"Montserrat", }} onClick={handleSTRClicked}>Candidate Strengths & Skills Lacking</button>
                        <button className="btn btn-secondary p-3" id='score' style={{color:'#887e7e', backgroundColor:'white', borderColor:'white', marginLeft:'2% ! important', paddingTop:'0px !important', fontFamily:"Montserrat", }} onClick={handleCandClicked}>Candidate Score and Resume Assessment</button>
                        <button className="btn btn-secondary p-3" id='profile' style={{color:'#887e7e', backgroundColor:'white', borderColor:'white', marginLeft:'2% ! important', paddingTop:'0px !important', fontFamily:"Montserrat", }} onClick={handleProfileClicked}>Profile Comparison</button>
                        <button className="btn btn-secondary p-3" id='tech' style={{color:'#887e7e', backgroundColor:'white', borderColor:'white', marginLeft:'2% ! important', paddingTop:'0px !important', fontFamily:"Montserrat", }} onClick={handleTechClicked}>Technical Questions</button>
                        <button className="btn btn-secondary p-3" id='behavioral' style={{color:'#887e7e', backgroundColor:'white', borderColor:'white', marginLeft:'2% ! important', paddingTop:'0px !important', fontFamily:"Montserrat", }} onClick={handleBehavioralClicked}>Behavioral Questions</button>
                    </div>
                    <div className='container' style={{width:'100%', height:'20px', backgroundColor:'#f5f5f5', "max-width":'100%'}}></div>
               
               
                { STRClicked && (
                    <>
                  
                    <div id= 'row main' style={{display:'flex', backgroundColor:'#f5f5f5'}}>


                        

                    <div id='row main' style={{width:'100%', 'padding-left':'6% !important'}}>
                    <div className='file-upload-info d-flex justify-content-center' style={{color:'white', width:'97% !important', marginLeft:'3%', borderTopLeftRadius:'6px', borderTopRightRadius:'6px', backgroundColor:'#a3c87a'}}>
                        <p className='m-3 p-0' style={{fontFamily:"Montserrat"}} >
                            Candidate Strengths
                        </p>
                    </div>
                        <TextareaAutosize
                            type="text"
                            className='newResponse interview-bubble response-text'
                            value={args.candidateInfo.strengths || ''}
                            style={{marginLeft:'3%'}}
                            onChange={event => handleNotesChange('strengths', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>
                  

                   
                    <div id='row main' style={{width:'100%'}}>
                    <div className='file-upload-info d-flex justify-content-center' style={{marginLeft:'3%', color:'white', width:'97%', borderTopLeftRadius:'6px', borderTopRightRadius:'6px', backgroundColor:'#a3c87a',}}>
                        <p className='m-3 p-0' style={{fontFamily:"Montserrat"}}>
                            Skills Lacking
                        </p>
                    </div>
                        <TextareaAutosize
                            type="text"
                            className='newResponse interview-bubble response-text'  
                            style={{marginLeft:'5% !important', height:'60%'}}
                            value={args.candidateInfo.lacking_qualities || ''}
                            onChange={event => handleNotesChange('lackingQualities', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>
                    </div>
                    </>
                    )}
                    

                { CandidateClicked && (
                    <>
                    <div className='file-upload-info d-flex justify-content-center' style={{backgroundColor: '#9797bf', marginLeft:'6%', marginRight:'4%', color:'white'}}>
                        <p className='m-3 p-0' style={{fontFamily:"Montserrat"}}>
                            Candidate and Resume Assessment
                        </p>
                    </div>
                    <div id='d-flex justify-content-center' style={{backgroundColor:'#f5f5f5'}}>
                        <TextareaAutosize
                            type="text"
                            className='newResponse interview-bubble response-text'
                            value={args.candidateInfo.assessment || ''}
                            style={{width:'90%', marginLeft:'6%'}}
                            onChange={event => handleNotesChange('assessment', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>
                    </>
                )}   



                { ProfileClicked && (
                    <> 
                    <div className='file-upload-info d-flex justify-content-center' style={{backgroundColor: '#f5cb67', marginLeft:'5%', marginRight:'5%', color:'white'}}>
                        <p className='m-3 p-0' style={{fontFamily:"Montserrat"}}>
                            LinkedIn Profile & Resume Comparison
                        </p>
                    </div>
                    <div id='d-flex justify-content-center'>
                        <TextareaAutosize
                            type="text"
                            className='newResponse interview-bubble response-text'
                            value={args.candidateInfo.linkedCompare || ''}
                            style={{width:'90%', marginLeft:'5%'}}
                            onChange={event => handleNotesChange('linkedCompare', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>
                  
                    <div className='file-upload-info d-flex justify-content-center' style={{backgroundColor: '#f5cb67', marginLeft:'5%', marginRight:'5%', marginTop:'3%', color:'white'}}>
                        <p className='m-3 p-0' style={{fontFamily:"Montserrat"}}>
                            Candidate Profile Image
                        </p>
                    </div>

                    
                    <div id='d-flex justify-content-center'>
                        <div style={{width:'90%', marginLeft:'5%', marginBottom:'2%', backgroundColor:'white'}}>
                    <Image style={{paddingLeft:"30%" , display:"flex"}} src={args.candidateInfo.imageSRC}  width="70%" height="60%" ></Image>
                        </div>
                    </div>
                   
                  
                    </>
                    )}



                { TechnicalClicked && (
                    <>
                    <div className='file-upload-info d-flex justify-content-center' style={{backgroundColor: '#7F7EB0', marginLeft:'5%', marginRight:'5%', color:'white', marginBottom:'5%'}}>
                        <p className='m-3 p-0' style={{fontFamily:"Montserrat"}}>
                            5 Technical Interview Questions
                        </p>
                    </div>
                    {args.candidateInfo.technical_questions.map((text, num) => (
                    <>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-2 m-0 blob-title' style={{color:'grey'}}>Question {num + 1}:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`tq.${num}`}
                                        type="text"
                                        className='newResponse interview-bubble response-text'
                                        value={text.question || ''}
                                        onChange={event => handleNotesChange('technical', 'question', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                <div className='col-2 m-0 blob-title' style={{color:'mediumpurple'}}>Look For:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`ta.${num}`}
                                        type="text"
                                        className='newResponse interview-bubble query-text'
                                        value={text.answer || ''}
                                        onChange={event => handleNotesChange('technical', 'answer', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                <div className='col-2 m-0 blob-title' style={{color:'grey'}}>Notes:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`tn.${num}`}
                                        type="text"
                                        className='newResponse interview-bubble notes-text'
                                        value={text.notes || ''}
                                        onChange={event => handleNotesChange('technical', 'notes', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                {num < args.candidateInfo.technical_questions.length -1 ? (
                                    <div className='separator mt-3 mb-5'></div>
                                ): null}
                            </div>
                        </div>
                    </>
                    ))}  
                    <div className='d-flex justify-content-center'>
                        <button className='btn btn-success ' style={{fontFamily:"Montserrat"}} onClick={() => customQuestion('technical')}>Add Custom Technical Question</button>
                    </div>
                    </>
                )}


                { BehavioralClicked && (
                        <>
                    <div className=' file-upload-info d-flex justify-content-center ' style={{backgroundColor: '#4CABAB', marginLeft:'5%', marginRight:'5%', color:'white', marginBottom:'5%'}}>
                        <p className='m-3 p-0'  style={{fontFamily:"Montserrat"}}>
                            3 Behavioral Interview Questions
                        </p>
                    </div>
                    {args.candidateInfo.general_questions.map((text, num) => (
                    <>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-2 m-0 blob-title' style={{color:'grey'}}>Question {num + 1}:</div>
                                <div className='col-10'>
                                    <TextareaAutosize
                                        key={`gq.${num}`}
                                        type="text"
                                        className='newResponse interview-bubble response-text '
                                        value={text.question || ''}
                                        onChange={event => handleNotesChange('general', 'question', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                <div className='col-2 m-0 blob-title' style={{color:'#4CABAB'}} >Look For:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`ga.${num}`}
                                        type="text"
                                        className='newResponse interview-bubble'
                                        value={text.answer || ''}
                                        onChange={event => handleNotesChange('general', 'answer', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                <div className='col-2 blob-title' style={{color:'grey'}}>Notes:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`gn.${num}`}
                                        type="text"
                                        className='newResponse interview-bubble notes-text'
                                        value={text.notes || ''}
                                        onChange={event => handleNotesChange('general', 'notes', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                {num < args.candidateInfo.general_questions.length -1 ? (
                                    <div className='separator mt-3 mb-5'></div>
                                ): null}
                            </div>
                        </div>
                    </>
                    ))}

                 

                    <div className='d-flex justify-content-center'>
                        <button className='btn btn-success'  style={{fontFamily:"Montserrat"}} onClick={() => customQuestion('behavioral')}>Add Custom Behavioral Question</button>
                    </div>

                    <div className='file-upload-info d-flex justify-content-center'>
                        <p className='m-3 p-0'  style={{fontFamily:"Montserrat"}}>
                            Overall Notes
                        </p>
                    </div>
                    <div id='d-flex justify-content-center'>
                        <TextareaAutosize
                            type="text"
                            className='interview-bubble mx-auto response-text'
                            minRows={5}
                            value={args.candidateInfo.overall_notes || ''}
                            onChange={event => handleNotesChange('overall', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>
                    </>
                )}

                { ShowDownload && (
                    
                    <>
                
                 <button type="button" className="btn btn-primary" style={{marginLeft:'40%', marginTop:'5%', width:'20%', marginBottom:'5%'}} onClick={() => Download_Current_Interview()}>Download</button>
                 </>
                )}
               
               
                </div>
            </div>
            </div>
        </>
    )
}
export default DisplayInterview;